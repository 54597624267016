import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { Modal } from '../common';

const DeleteInvoiceModal = ({ isModalVisible, onApply, onClose }) => {
  return (
    <Modal isVisible={isModalVisible} size="small">
      <header className="heading">{i18n.t('carrierInvoice.deleteModalMessage')}</header>
      <div className="panel__section">
        <div className="panel__actions">
          <button type="button" className="button button__secondary" onClick={onClose}>
            {i18n.t('button.cancel')}
          </button>
          <button type="button" className="button button__warning" onClick={onApply}>
            {i18n.t('button.delete')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteInvoiceModal.propTypes = {
  isModalVisible: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeleteInvoiceModal.defaultProps = {
  isModalVisible: false,
};

export default React.memo(DeleteInvoiceModal);
