import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { Children } from '../../types';

const Modal = ({ isVisible, children, size }) => {
  useEffect(() => {
    if (isVisible) document.body.classList.add('-no-scroll');
    else setTimeout(() => document.body.classList.remove('-no-scroll'), 250);
  }, [isVisible]);

  return (
    <CSSTransition in={isVisible} unmountOnExit timeout={250}>
      <div className="modal">
        <div className={`modal__content -${size}`}>{children}</div>
      </div>
    </CSSTransition>
  );
};

Modal.propTypes = {
  children: Children.isRequired,
  isVisible: PropTypes.bool,
  size: PropTypes.string,
};

Modal.defaultProps = {
  isVisible: false,
  size: '',
};

export default React.memo(Modal);
