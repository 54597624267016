import React from 'react';
import PropTypes from 'prop-types';

import { MonthNavigator } from '../common';
import { SelectField } from '../common/fields';
import { Carrier } from '../../types';

const ReportListHeader = ({ carriers, onDateChange, onCarrierSelect, isMonthNavigatorDisabled, disableMonthNavigatorMessage }) => {
  return (
    <>
      <div className="grid__row">
        <div className="grid__col -x-12 -align-right">
          <SelectField
            name="zohoClient"
            options={carriers}
            onChange={onCarrierSelect}
            className="input__select -header"
            labelKey="contactName"
            valueKey="contactId"
            isClearable
          />
          <MonthNavigator onChange={onDateChange} isDisabled={isMonthNavigatorDisabled} disableMessage={disableMonthNavigatorMessage} />
        </div>
      </div>
    </>
  );
};

ReportListHeader.propTypes = {
  carriers: PropTypes.arrayOf(Carrier),
  endDate: PropTypes.shape({}),
  onDateChange: PropTypes.func,
  onCarrierSelect: PropTypes.func.isRequired,
  startDate: PropTypes.shape({}),
  isMonthNavigatorDisabled: PropTypes.bool,
  disableMonthNavigatorMessage: PropTypes.string,
};

ReportListHeader.defaultProps = {
  carriers: [],
  endDate: {},
  onDateChange: () => undefined,
  startDate: {},
  isMonthNavigatorDisabled: false,
  disableMonthNavigatorMessage: '',
};

export default React.memo(ReportListHeader);
