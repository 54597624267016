import firebase from 'firebase/app';

import zohoAuthHelpers from '../helpers/zohoAuthHelpers';
import { toCamelCase } from '../helpers/apiHelpers';

const authenticate = async () => {
  const accesssToken = localStorage.getItem('zohoAccessToken');
  const refreshToken = localStorage.getItem('zohoRefreshToken');

  if (!accesssToken || !refreshToken) {
    zohoAuthHelpers.redirectToZohoLogin();
  } else if (!zohoAuthHelpers.isTokenValid()) {
    const getZohoTokens = firebase.functions().httpsCallable('getZohoTokens');

    const response = await getZohoTokens({ refreshToken, redirectUri: process.env.REACT_APP_ZOHO_REDIRECT_URL });

    zohoAuthHelpers.setTokens(response.data);
  }
};

const generateTokens = async code => {
  const getZohoTokens = firebase.functions().httpsCallable('getZohoTokens');

  const response = await getZohoTokens({ code, redirectUri: process.env.REACT_APP_ZOHO_REDIRECT_URL });

  zohoAuthHelpers.setTokens(response.data);
};

const getCarriers = async (page = 1, carriers = []) => {
  await authenticate();

  const getZohoContacts = firebase.functions().httpsCallable('getContacts');

  return getZohoContacts({ accessToken: localStorage.getItem('zohoAccessToken'), page }).then(response => {
    const { contacts } = response.data;
    if (response.data && response.data.page_context && response.data.page_context.has_more_page) {
      const newCarriers = carriers.concat(contacts.map(toCamelCase));
      return getCarriers(page + 1, newCarriers);
    }

    const newCarriers = carriers.concat(contacts.map(toCamelCase));
    return newCarriers;
  });
};

const getInvoiceItems = async (page = 1, invoiceItems = []) => {
  await authenticate();

  const getItems = firebase.functions().httpsCallable('getItems');

  return getItems({ accessToken: localStorage.getItem('zohoAccessToken'), page }).then(response => {
    const { items } = response.data;
    if (response.data && response.data.page_context && response.data.page_context.has_more_page) {
      const newItem = invoiceItems.concat(items.map(toCamelCase));
      return getInvoiceItems(page + 1, newItem);
    }

    const newInvoiceItems = invoiceItems.concat(items.map(toCamelCase));
    return newInvoiceItems;
  });
};

const getInvoice = async id => {
  await authenticate();

  const getZohoInvoice = firebase.functions().httpsCallable('getInvoice');

  const response = await getZohoInvoice({ accessToken: localStorage.getItem('zohoAccessToken'), id });

  return response && response.data && response.data.invoice ? toCamelCase(response.data.invoice) : undefined;
};

const createInvoice = async invoice => {
  await authenticate();

  const createZohoInvoice = firebase.functions().httpsCallable('createInvoice');

  const response = await createZohoInvoice({ accessToken: localStorage.getItem('zohoAccessToken'), invoice });

  return toCamelCase(response.data.invoice);
};

const updateInvoice = async (id, invoice) => {
  await authenticate();

  const updateZohoInvoice = firebase.functions().httpsCallable('updateInvoice');

  const response = await updateZohoInvoice({ accessToken: localStorage.getItem('zohoAccessToken'), id, invoice });

  return toCamelCase(response.data.invoice);
};

const deleteInvoice = async id => {
  await authenticate();

  const deleteZohoInvoice = firebase.functions().httpsCallable('deleteInvoice');

  await deleteZohoInvoice({ accessToken: localStorage.getItem('zohoAccessToken'), id });
};

export default { getCarriers, getInvoiceItems, getInvoice, createInvoice, updateInvoice, deleteInvoice, generateTokens };
