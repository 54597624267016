import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_PELCHAT_ID;

const isMatching = rows => {
  return rows.find(r => {
    return r.find(d => d.includes('Transport Pelchat et Frères inc.'));
  });
};

const headerRowText = ['N°', 'ARTICLE', 'DESCRIPTION', 'NO.TRANS.', 'QUANTITÉ', 'UNITÉ', 'PRODUITS', 'CMV', 'PROFIT', 'MARGE'];

const getRowType = row => {
  const parsedRow = row
    .join(' ')
    .toUpperCase()
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split(' ');

  if (row.length === 1 && _.startsWith(row[0], 'Page')) return 'PAGE';
  if (row.length === 1 && _.startsWith(row[0], 'Rapport sommaire des ventes auprès des clients du ')) return 'DATE';
  if (_.intersection(parsedRow, headerRowText).length >= 5) return 'HEADER';
  if (row.length === 1) return 'POSSIBLE_CLIENT_NAME';
  if (row.length > 4) return 'DATA';
  if (row.length === 0) return 'EMPTY';

  return 'UNKNOWN';
};

const analyseData = rows => {
  const transports = [];
  let client = null;
  let date = null;
  let isDataRow = false;
  let isLastRowHeader = false;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER') {
      isDataRow = false;
      isLastRowHeader = true;
    } else if (rowType === 'DATE') {
      isDataRow = false;
      date = _.get(row[0].split(' '), '8', '');
    } else if (rowType === 'POSSIBLE_CLIENT_NAME' && isLastRowHeader) {
      isDataRow = true;
      isLastRowHeader = false;
      client = _.get(row, '0', '');
    } else if (isDataRow && row.length > 4) {
      const textAmount = _.get(row, `${row.length - 2}`, '');
      const amount = formatAmount(textAmount);
      transports.push({ date, from: null, to: null, description: row[0], amount, client, currency: 'CAD', commissionType: 'TL' });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
