import React from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';

const ListActionBar = ({ children, className }) => <div className={`list__action-bar ${className}`}>{children}</div>;

ListActionBar.propTypes = { children: Children.isRequired, className: PropTypes.string };

ListActionBar.defaultProps = {
  className: '',
};

export default React.memo(ListActionBar);
