import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';

const TransportListTabs = ({ activeTab, onActiveTabChange, tabs }) => {
  return (
    <div className="tabs">
      {tabs.map(tab => (
        <button
          key={tab.key}
          type="button"
          className={`tabs__tab ${activeTab === tab.key ? '-active' : ''}`}
          onClick={() => onActiveTabChange(tab.key)}>
          {tab.name}
          {tab.warning && <WarningIcon />}
        </button>
      ))}
      <div className="tabs__seal" />
    </div>
  );
};

TransportListTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onActiveTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, name: PropTypes.string })).isRequired,
};

export default React.memo(TransportListTabs);
