import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import firebase from 'firebase/app';
import moment from 'moment';

import useIsMounted from './useIsMounted';

const useAgent = agent => {
  const [state, setState] = useState({ result: undefined, isPending: false });
  const isMounted = useIsMounted();
  let previousResult;

  const call = useCallback(async (...params) => {
    setState({ result: previousResult, isPending: true });

    try {
      const result = await agent(...params);
      const data = result && result.data ? result.data : result;
      if (isMounted()) {
        previousResult = data;
        setState({ result: data, isPending: false });
      }

      return result;
    } catch (error) {
      if (isMounted()) {
        previousResult = undefined;
        setState({ result: undefined, isPending: false });
        if (error.code === '404') {
          window.location.replace('/404');
        } else {
          const db = firebase.firestore();
          await db
            .collection('errors')
            .add({
              message: error.message,
              stack: error.stack,
              datetime: new Date(
                moment()
                  .utc()
                  .format()
              ),
            })
            .catch(() => undefined);
          toast.error(i18n.t('globalError'), { autoClose: 5000 });
        }
      }

      throw error;
    }
  }, []);

  return [call, state.result, state.isPending];
};

export default useAgent;
