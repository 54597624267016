import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import _ from 'lodash';
import moment from 'moment';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';

const EditableDateField = ({ editClassName, wrapperClassName, name, fieldValue, onChange }) => {
  const [isInEdition, setIsInEdition] = useState(false);
  const [value, setValue] = useState(null);

  const handleEditClick = useCallback(() => setIsInEdition(true), []);

  const handleBlur = useCallback(({ focused }) => {
    if (!focused) {
      // TODO: Find a better way to avoid value flickering
      setTimeout(() => setIsInEdition(false));
    }
  }, []);

  const handleDateChange = useCallback(
    date => {
      setValue(date);
      onChange(date.toDate());
    },
    [onChange]
  );

  useEffect(() => setValue(moment(fieldValue)), [fieldValue]);

  return (
    <div className={`editable ${wrapperClassName}`}>
      {!isInEdition && (
        <button className={`editable__button ${editClassName}`} type="button" onClick={handleEditClick}>
          {value ? value.format('YYYY-MM-DD') : ''}
          <EditIcon className="icon -margin-left" />
        </button>
      )}

      {isInEdition && (
        <SingleDatePicker
          id={name}
          date={value}
          onDateChange={handleDateChange}
          focused
          onFocusChange={handleBlur}
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          isOutsideRange={_.noop}
          displayFormat="YYYY-MM-DD"
          noBorder
        />
      )}
    </div>
  );
};

EditableDateField.propTypes = {
  editClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  fieldValue: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

EditableDateField.defaultProps = {
  editClassName: '',
  wrapperClassName: '',
  fieldValue: undefined,
  onChange: _.noop,
};

export default React.memo(EditableDateField);
