import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_CRS_EXPRESS_ID;

const isMatching = rows => {
  return rows.find(r => {
    return r.find(d => d.includes('CRS Express Inc.'));
  });
};

const dataRegex = /(?<date>\d+\/\d+\/\d+)\s(?<noFact>\S+)\s(?<noClient>\w+)\s(?<nomClient>.+?)(?:\s(?<noProduit>\d+)(?<nomItem>\s.+))?\s(?<prixUnit>\d{1,3}(?:,\d{3})*(?:\.\d+)?)\s(?<qantite>\d{1,3}(?:,\d{3})*(?:\.\d+)?)\s(?<total>\d{1,3}(?:,\d{3})*(?:\.\d+)?)/;

const analyseData = rows => {
  const transports = [];

  rows.forEach(row => {
    const rowAsString = row.join(' ');
    if (dataRegex.test(rowAsString)) {
      const matchedGroups = rowAsString.match(dataRegex).groups;

      const [day, month, year] = matchedGroups.date.split('/');
      const description = matchedGroups.nomItem ? matchedGroups.nomItem.trim() : '';

      transports.push({
        date: `${year}-${month}-${day}`,
        from: null,
        to: null,
        description,
        amount: formatAmount(matchedGroups.total),
        client: matchedGroups.nomClient,
        currency: 'CAD',
        commissionType: 'TL',
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
