const isTokenValid = () => {
  const expiresIn = localStorage.getItem('zohoExpiresIn');

  if (!expiresIn) return false;

  const expiresAt = JSON.parse(expiresIn);
  return new Date().getTime() < expiresAt;
};

const setTokens = data => {
  localStorage.setItem('zohoAccessToken', data.access_token);
  if (data.refresh_token) localStorage.setItem('zohoRefreshToken', data.refresh_token);
  localStorage.setItem('zohoExpiresIn', JSON.stringify(data.expires_in + new Date().getTime()));
};

const redirectToZohoLogin = () => {
  localStorage.setItem('redirectUrl', window.location.pathname);
  const urlClientId = `client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}`;
  const urlRedirect = `redirect_uri=${process.env.REACT_APP_ZOHO_REDIRECT_URL}`;
  window.location = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.fullaccess.all&${urlClientId}&response_type=code&${urlRedirect}&access_type=offline&prompt=consent`;
};

export default { isTokenValid, setTokens, redirectToZohoLogin };
