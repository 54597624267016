import PropTypes from 'prop-types';

const User = PropTypes.shape({
  accessToken: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  metadata: PropTypes.shape({
    lastSignInTime: PropTypes.string,
    creationTime: PropTypes.string,
  }),
  phoneNumber: PropTypes.string,
  photoURL: PropTypes.string,
  refreshToken: PropTypes.string,
  uid: PropTypes.string,
});

export default User;
