import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { FileUpload } from '../common';

const FileUploadPanel = ({ onShowChooseCarrierClick, onFileChange }) => {
  return (
    <div className="layout__content layout__button-center">
      <div className="panel -small -center">
        <FileUpload label={i18n.t('upload.uploadFile')} onChange={onFileChange} />
      </div>

      <button type="button" className="button -margin-top button__link" onClick={onShowChooseCarrierClick}>
        {i18n.t('upload.chooseCarrierFirst')}
      </button>
    </div>
  );
};

FileUploadPanel.propTypes = {
  onShowChooseCarrierClick: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
};

export default React.memo(FileUploadPanel);
