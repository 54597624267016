import moment from 'moment';
import _ from 'lodash';

const carrierId = process.env.REACT_APP_MACHITECH_ID;

const isMatching = () => false;

const months = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];

const headerRowText = [
  'CLIENT NAME',
  'NAME',
  'DESTINATION',
  'CARRIER NAME',
  'EQUIPEMENT & DIMS',
  '# P/O',
  'RATE',
  'LOADING DATE',
  'LOADING DATE & HOUR',
  'DELIVERY DATE',
  'CLIENT CONTACT & PHONE #',
  'APPOINTMENT',
  'FOLLOW UP DATE',
  'DELIVERED',
  'INFO SUPPLÉMENTAIRE',
  'HOUR',
];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));

  if (_.intersection(parsedRow, headerRowText).length >= 5) return 'HEADER';
  if (row.length >= 5 && !!row[5]) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const transports = [];
  const previousMonth = moment().subtract(1, 'months');
  const sheetNames = Object.keys(data);
  const sheetName = sheetNames.find(sn => sn.includes(previousMonth.year()));
  const monthName = months[previousMonth.month()];
  let isCurrentMonth = false;

  data[sheetName].forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER') {
      isCurrentMonth = row[0].toUpperCase().includes(monthName);
    } else if (rowType === 'DATA' && isCurrentMonth) {
      const isPoNumberDuplicate = transports.find(t => t.poNumber === row[5]);

      if (!isPoNumberDuplicate)
        transports.push({
          client: 'Machitech',
          poNumber: row[5],
          description: `${row[5]} - ${row[1] || ''} - ${row[2] || ''} - ${row[3] || ''} - ${row[4] || ''}`,
          date: row[7],
          currency: 'CAD',
          amount: 50,
          rate: 1,
        });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData, metaData: { hasCustomRates: true, groupeInvoiceLineBy: 'poNumber' } };
