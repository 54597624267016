import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_TRANSPORT_BOURASSA_ID;

const isMatching = () => false;

const months = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUN', 'JUL', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'];

const formatDate = date => {
  const parts = date.trim().split('-');

  const month = months.findIndex(m => m.includes(_.deburr(parts[1]).toUpperCase()));

  return `${parts[2]}-${String(month + 1).padStart(2, '0')}-${parts[0]}`;
};

const cleanText = text => _.deburr(text).toUpperCase();

const headerRowText = ['CLIENT', 'DATE', 'PROBILL', 'MONTANT DU', 'MONTANT FACTURE AU CLIENT'];

const getRowType = row => {
  const matchingHeaders = row.filter(data => headerRowText.find(header => cleanText(data).includes(cleanText(header))));

  if (matchingHeaders.length >= 3) return 'HEADER';

  const rowWithoutEmpty = _.compact(row);
  const hasTotalText = row.find(data => data.toUpperCase().includes('TOTAL'));

  if (rowWithoutEmpty.length === 2 || hasTotalText) return 'TOTAL';

  if (row.length === 5 || row.length === 6) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = sheets => {
  const rows = Object.values(sheets)[0];
  const transports = [];
  let clientIndex = 0;
  let dateIndex = 1;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER') {
      dateIndex = row.findIndex(data => data.toUpperCase().includes('DATE'));
      clientIndex = row.findIndex(data => data.toUpperCase().includes('CLIENT'));
    } else if (rowType === 'DATA') {
      transports.push({
        client: row[clientIndex].trim(),
        date: formatDate(row[dateIndex]),
        description: `No probill ${row[2].trim()} ${row.length === 6 ? row[3].trim() : ''}`,
        amount: formatAmount(row[row.length - 1]),
        currency: 'CAD',
        commissionType: 'LTL',
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
