import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_TRANSPORT_TFI_4_ID;

const isMatching = () => false;

const headerRowText = [
  'CLIENT',
  'ORIGINE',
  'DESTINATION',
  'RÉFÉRENCES',
  'REFERENCES',
  'DATE',
  '# FACTURE',
  'QUANTITÉ',
  'QUANTITE',
  'TAUX',
  'TOTAL VOYAGE',
  '3%',
];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));
  const rowWithoutEmpty = parsedRow.filter(data => data !== '');

  if (_.intersection(parsedRow, headerRowText).length >= 3) return 'HEADER';
  if (rowWithoutEmpty.length >= 1 && rowWithoutEmpty[0].toUpperCase().includes('TOTAL')) return 'TOTAL_ROW';
  if (rowWithoutEmpty.length === 11) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const [rows] = Object.values(data);
  const transports = [];

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'DATA') {
      const rate = Number(row[10].replace('%', '').replace(',', '.')) / 100;
      transports.push({
        client: row[0],
        from: row[1],
        to: row[2],
        date: row[4],
        currency: 'CAD',
        amount: formatAmount(row[8]),
        poNumber: row[5],
        rate: Number(rate.toFixed(5)),
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData, metaData: { hasCustomRates: true } };
