import React, { useMemo } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { EditableDateField } from '../common/fields';

const CarrierInvoiceHeader = ({ title, onSendToZoho, onInvoiceDateChange, invoice, onDeleteClick, onUpdateZohoInvoice, onReuploadFile, errors }) => {
  const canSendToZoho = useMemo(() => {
    let canSend = true;

    errors.forEach(e => {
      if (e.error === 'isNaN') canSend = false;
      else if (e.error === 'isNotZohoItem') canSend = false;
    });

    return canSend;
  }, [errors]);

  return (
    <div className="header -out-of-panel">
      <div className="header__left">
        <EditableDateField
          name="invoiceDate"
          wrapperClassName="heading -block -small -transparent"
          editClassName="-align-left"
          fieldValue={invoice.date}
          onChange={onInvoiceDateChange}
        />
        <h3 className="heading -bold">{title}</h3>
      </div>
      <div className="header__right">
        <input type="file" name="reupload" id="reupload" hidden accept="application/pdf, .xlsx, .xls" multiple={false} onChange={onReuploadFile} />
        {!_.isEmpty(invoice) && !!invoice.zohoId && (
          <a
            href={`https://books.zoho.com/app#/invoices/${invoice.zohoId}`}
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="button button__tertiary">
            {i18n.t('carrierInvoice.viewInvoice')}
          </a>
        )}
        <label className="button button__tertiary" htmlFor="reupload">
          {i18n.t('carrierInvoice.reupload')}
        </label>
        <button type="button" className="button button__warning" onClick={onDeleteClick}>
          {i18n.t('button.delete')}
        </button>
        {!_.isEmpty(invoice) && !invoice.zohoId && canSendToZoho && (
          <>
            <button type="button" className="button button__primary" onClick={onSendToZoho}>
              {i18n.t('carrierInvoice.sendToZoho')}
            </button>
          </>
        )}
        {!_.isEmpty(invoice) && !!invoice.zohoId && canSendToZoho && (
          <>
            <button type="button" className="button button__primary" onClick={onUpdateZohoInvoice}>
              {i18n.t('carrierInvoice.updateZohoInvoice')}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

CarrierInvoiceHeader.propTypes = {
  onSendToZoho: PropTypes.func.isRequired,
  title: PropTypes.string,
  invoice: PropTypes.shape({ date: PropTypes.instanceOf(Date), zohoId: PropTypes.string }),
  onDeleteClick: PropTypes.func.isRequired,
  onInvoiceDateChange: PropTypes.func.isRequired,
  onUpdateZohoInvoice: PropTypes.func.isRequired,
  onReuploadFile: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      client: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.string,
    })
  ),
};

CarrierInvoiceHeader.defaultProps = {
  title: '',
  invoice: {},
  errors: [],
};

export default React.memo(CarrierInvoiceHeader);
