import React from 'react';

const Loader = () => (
  <div className="loader -visible">
    <figure className="loader__spinner">
      <i className="loader__animation" />
      <i className="loader__animation" />
    </figure>
  </div>
);

export default React.memo(Loader);
