import XLSX from 'xlsx';
import _ from 'lodash';

const removeTrailingColumns = data => {
  const result = [];

  _.forEachRight(data, d => {
    if (d !== '' || result.length > 0) result.push(d);
  });

  return result.reverse();
};

const cleanData = data => {
  return _.map(data, row => removeTrailingColumns(Object.values(row)));
};

export const getXLSXData = xlsx => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();

    reader.onload = e => {
      const result = {};
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: 'array', dateNF: 'YYYY-MM-DD', cellDates: true });

      wb.SheetNames.forEach(name => {
        const ws = wb.Sheets[name];
        const xlsxData = XLSX.utils.sheet_to_json(ws, { raw: false, defval: '' });
        result[name] = cleanData(xlsxData);
      });

      resolve(result);
    };

    reader.onerror = e => reject(e);

    reader.readAsArrayBuffer(xlsx);
  });
};

export default { getXLSXData };
