import _ from 'lodash';

const carrierId = process.env.REACT_APP_JAG_ID;

const isMatching = rows => {
  return rows.find(r => {
    return r.find(d => d.includes('LES SERVICES JAG INC.'));
  });
};

const overlappingFrom = [
  { error: '- ONT-JOLI', correction: '- MONT-JOLI' },
  { error: '- ONTREAL', correction: '- MONTREAL' },
  { error: '- SAINT-ECATHERINE', correction: '- SAINTE-CATHERINE' },
  { error: '- POINTE-AUXT-REMBLES', correction: '- POINTE-AUX-TREMBLES' },
  { error: '- SAINT-EMARTINE', correction: '- SAINTE-MARTINE' },
  { error: '- LANOAIE', correction: '- LANORAIE' },
  { error: '- ATANE', correction: '- MATANE' },
];

const headerRowText = ['NOM', 'CLIENT', 'ORIGINE', 'DESTINATION', 'FACTURE', 'FACTURÉ', 'DONNÉES', 'TRANSPORT', 'FS', '$'];
const summaryHeaderRowText = ['CLIENT', 'VENTE', 'TAUX', 'COMMISSION', 'TPS', 'TVQ', 'TOTAL'];

const formatAmount = amount => {
  let result = '';

  if (amount) {
    result = amount
      .replace(/\s+/g, '')
      .replace(' ', '')
      .replace('$', '')
      .trim();
  }

  if (result[result.length - 3] === ',') {
    result = `${result.substring(0, result.length - 3)}.${result.substring(result.length - 2)}`;
  }

  return !Number.isNaN(Number(result.replace(',', ''))) ? Number(result.replace(',', '')) : result;
};

const getRowType = row => {
  const parsedRow = row
    .join(' ')
    .toUpperCase()
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split(' ');

  if (_.intersection(parsedRow, headerRowText).length >= 5) return 'HEADER';
  if (_.intersection(parsedRow, summaryHeaderRowText).length >= 5) return 'SUMMARY_HEADER';
  if (row.length === 6 && _.startsWith(row[0], 'Sous-Total')) return 'SUMMARY_SUB_TOTAL';
  if (row.length === 3 && _.startsWith(row[0], 'Total')) return 'SUB_TOTAL';
  if (row.length === 1) return 'TITLE';
  if (row.length === 0) return 'EMPTY';
  if (row.length === 2 && _.startsWith(row[0], 'Pour le mois de')) return 'MONTH';
  if (row.length > 4) return 'DATA';

  return 'UNKNOWN';
};

const fixRow = row => {
  const dateIndex = row.length - 3;

  if (row[dateIndex].length > 10) {
    const splittedDateAndReceiptNumber = row[dateIndex].split(' ');
    return [...row.slice(0, dateIndex), ...splittedDateAndReceiptNumber, ...row.slice(dateIndex + 1)];
  }

  return row;
};

const unsplitFromAndClient = (clientAndFrom, client) => {
  let result = clientAndFrom.replace(/\s+/g, '');
  let clientChars = [...client.replace(/\s+/g, '')];
  let i = 0;

  [...clientAndFrom.replace(/\s+/g, '')].forEach(c => {
    if (c === clientChars[0]) {
      clientChars = clientChars.slice(1);
      result = result.substring(0, i) + result.substring(i + 1);
    } else {
      i += 1;
    }
  });

  if (result[2] === '-') {
    result = `${result.substring(0, 2)} - ${result.substring(3)}`;
  }

  const overlapping = overlappingFrom.find(o => result.includes(o.error));

  if (overlapping) {
    result = result.replace(overlapping.error, overlapping.correction);
  }

  return result;
};

const analyseData = rows => {
  const transports = [];
  const transportsToFix = [];
  const subTotalRows = [];
  const summaryRows = [];
  let isSummaryDataRow = false;
  let isDataRow = false;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'SUMMARY_HEADER') {
      isSummaryDataRow = true;
    } else if (rowType === 'SUMMARY_SUB_TOTAL') {
      isSummaryDataRow = false;
    } else if (isSummaryDataRow) {
      summaryRows.push(row);
    } else if (rowType === 'HEADER') {
      isDataRow = true;
    } else if (rowType === 'SUB_TOTAL') {
      subTotalRows.push(row);
      isDataRow = false;
    } else if (isDataRow && row.length > 4) {
      const fixedRow = fixRow(row);

      if (fixedRow.length === 7) {
        const amount = formatAmount(fixedRow[5]);
        const client = fixedRow[0];
        const summaryRow = summaryRows.find(s => s[0].includes(client));
        let rate;

        if (summaryRow) {
          rate = Number(summaryRow[2].replace('%', '').replace(',', '.')) / 100;
          transports.push({ date: fixedRow[4], from: fixedRow[1], to: fixedRow[2], amount, client, currency: 'CAD', rate });
        } else {
          transportsToFix.push({ row: fixedRow, subTotalIndex: subTotalRows.length, errorType: 'SPLIT' });
        }
      } else {
        transportsToFix.push({ row: fixedRow, subTotalIndex: subTotalRows.length, errorType: 'MERGE' });
      }
    }
  });

  transportsToFix.forEach(t => {
    const client = subTotalRows[t.subTotalIndex][0].replace('Total ', '');
    const isMergeError = t.errorType === 'MERGE';
    const fromWithError = isMergeError ? t.row[0] : `${t.row[0]} ${t.row[1]}`;
    const from = unsplitFromAndClient(fromWithError, client);
    const summaryRow = summaryRows.find(s => s[0].includes(client));
    let rate;

    if (summaryRow) rate = Number(summaryRow[2].replace('%', '').replace(',', '.')) / 100;

    transports.push({
      date: t.row[isMergeError ? 3 : 4],
      from,
      to: t.row[isMergeError ? 1 : 2],
      amount: formatAmount(t.row[isMergeError ? 4 : 5]),
      client,
      currency: 'CAD',
      rate,
    });
  });

  return transports;
};

export default { carrierId, isMatching, analyseData, metaData: { hasCustomRates: true } };
