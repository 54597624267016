const numberRegex = /^-?\d+\.?\d*$/;

export const toNumber = value => {
  if (!value && value !== 0) return null;
  if (String(value).includes('-')) return undefined;
  if (!numberRegex.test(value)) return undefined;

  return Number(value);
};

export const numberFormatter = value => {
  if (!value && Number(value) !== 0) return null;
  if (Number.isNaN(Number(value))) return undefined;

  return `${Number(value).toFixed(2)}`;
};

export const isNaN = value => {
  if (Number.isNaN(Number(value))) return true;

  return false;
};

export const isZero = value => {
  if (Number(value) === 0) return true;

  return false;
};

export const isNegative = value => {
  if (Number(value) < 0) return true;

  return false;
};

export const toMoney = value => {
  if (!value && value !== 0) return null;
  if (value === '-') return 0;
  if (Number.isNaN(Number(value))) return undefined;

  return Number(Number(value).toFixed(2));
};

export const toMoneyOrText = value => {
  if (!value && value !== 0) return null;
  if (value === '-') return 0;
  if (Number.isNaN(Number(value))) return value;

  return Number(Number(value).toFixed(2));
};

const intlMoneyFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' });

export const moneyFormatter = value => {
  if (!value && value !== 0) return null;
  if (Number.isNaN(Number(value))) return undefined;

  return `${intlMoneyFormatter.format(Number(value)).replace('$', '')} $`;
};

export const moneyOrTextFormatter = value => {
  if (!value && value !== 0) return null;
  if (Number.isNaN(Number(value))) return value;

  return `${intlMoneyFormatter.format(Number(value)).replace('$', '')} $`;
};

export const toPercentage = value => {
  if (!value) return null;
  if (Number(value) > 100 || Number(value) < 0) return undefined;

  return Number((Number(value) / 100).toFixed(5));
};

export const percentageFormatter = value => {
  if (!value && Number(value) !== 0) return null;
  if (Number.isNaN(Number(value))) return undefined;

  return `${Number(value)} %`;
};

export const fieldValidator = (rules, value) => {
  let hasErrors = false;

  if (Array.isArray(rules)) {
    rules.forEach(rule => {
      if (rule(value) === true) hasErrors = true;
    });
  } else if (rules(value) === true) hasErrors = true;

  return hasErrors;
};

export const isNotZohoItem = value => {
  if (!value) return true;
  if (value && !value.itemId) return true;

  return false;
};
