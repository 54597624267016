import moment from 'moment';
import _ from 'lodash';

const carrierId = process.env.REACT_APP_CHANTIER_CHIBOUGAMEAU_ID;

const isMatching = () => false;

const months = ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'];

const headerRowText = ['DESTINATION', 'TRANSPORTEUR', '# PO', 'CHARGEMENT', 'POIDS', 'TAUX', 'DATE LIV', 'INFORMATIONS SUPPLEMENTAIRES', 'DELIVERED'];

const cleanText = text => _.deburr(text).toUpperCase();

const getRowType = row => {
  const matchingHeaders = row.filter(data => headerRowText.find(header => cleanText(data).includes(header)));

  if (matchingHeaders.length >= 5) return 'HEADER';
  if (row[0].toUpperCase().includes('SEMAINE')) return 'NEW_WEEK';
  if (row.length >= 3 && !!row[2]) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const transports = [];
  const sheetNames = Object.keys(data);

  const previousMonth = moment().subtract(1, 'months');
  const year = previousMonth.year();
  const month = months[previousMonth.month()];

  const sheetName = sheetNames.find(tn => {
    const cleanName = _.deburr(tn.toUpperCase()).replace(' ', '');

    return cleanName === `${month}${year}`;
  });

  data[sheetName].forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'DATA') {
      const isPoNumberDuplicate = transports.find(t => t.poNumber === row[2]);

      if (!isPoNumberDuplicate)
        transports.push({
          client: 'Chantier Chinougameau',
          poNumber: row[2],
          description: `${row[2]} - ${row[1] || ''} - ${row[0] || ''}`,
          date: row[3],
          currency: 'CAD',
          amount: 20,
          rate: 1,
        });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData, metaData: { hasCustomRates: true, groupeInvoiceLineBy: 'poNumber' } };
