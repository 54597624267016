import React, { useCallback, useEffect, useContext, useState } from 'react';
import i18n from 'i18next';
import firebase from 'firebase/app';

import { Router } from '../types';
import { useAgent } from '../hooks';
import AppContext from '../contextes/AppContext';
import ReportAnalyser from '../helpers/analyser/ReportAnalyser';
import { pdfTablesAgent, invoiceAgent, zohoAgent } from '../agents';
import { Loader } from '../components/common';
import { getXLSXData } from '../helpers/reportHelpers';
import FileUploadPanel from '../components/file/FileUploadPanel';
import CarrierSelectModal from '../components/file/CarrierSelectModal';

const UploadPage = ({ history }) => {
  const appContext = useContext(AppContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataFailedToAnalyse, setDataFailedToAnalyse] = useState(null);
  const [fileFailedToAnalyse, setFileFailedToAnalyse] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [fileExtension, setFileExtension] = useState('');
  const [getPDFData, , isFetchingPDF] = useAgent(pdfTablesAgent.getPDFData);
  const [createInvoice, , isCreatingInvoice] = useAgent(invoiceAgent.create);
  const [getCarriers, carriers, isGettingCarriers] = useAgent(zohoAgent.getCarriers);

  const saveFile = useCallback(async (invoiceId, file) => {
    const storageRef = firebase.storage().ref();
    const ref = storageRef.child(`${invoiceId}/${file.name}`);
    await ref.put(file);
  }, []);

  const saveInvoice = useCallback(async (analysedInvoice, file) => {
    const newInvoiceId = await createInvoice(analysedInvoice);
    await saveFile(newInvoiceId, file);
    history.push(`/invoices/${newInvoiceId}`);
  }, []);

  const handleShowChooseAnalyser = useCallback(async () => {
    if (!carriers) {
      await getCarriers();
    }

    setSelectedCarrier(null);
    setDataFailedToAnalyse(null);
    setFileFailedToAnalyse(null);
    setIsModalVisible(true);
  }, [carriers]);

  const handleFileChange = useCallback(
    async files => {
      let data;
      const filePath = files[0].name.split('.');
      const extension = filePath[filePath.length - 1];
      setFileExtension(extension);

      if (extension === 'pdf') {
        data = await getPDFData(files[0]);
      } else if (extension === 'xlsx' || extension === 'xls') {
        data = await getXLSXData(files[0]);
      }

      const analysedInvoice = data ? ReportAnalyser.analyse(data, selectedCarrier, extension) : null;

      if (analysedInvoice) {
        saveInvoice(analysedInvoice, files[0]);
      } else {
        if (!carriers) {
          await getCarriers();
        }

        setDataFailedToAnalyse(data);
        setFileFailedToAnalyse(files[0]);
        setIsModalVisible(true);
      }
    },
    [carriers, selectedCarrier]
  );

  const handleApply = useCallback(
    async carrier => {
      setIsModalVisible(false);

      if (dataFailedToAnalyse) {
        const analysedInvoice = ReportAnalyser.analyse(dataFailedToAnalyse, carrier, fileExtension);

        if (analysedInvoice) saveInvoice(analysedInvoice, fileFailedToAnalyse);
      } else {
        setSelectedCarrier(carrier);
      }
    },
    [dataFailedToAnalyse, fileFailedToAnalyse, saveInvoice, fileExtension, setSelectedCarrier]
  );

  const handleClose = useCallback(() => {
    setIsModalVisible(false);
    setDataFailedToAnalyse(null);
    setFileFailedToAnalyse(null);
    setSelectedCarrier(null);
  }, []);

  useEffect(() => {
    appContext.dispatch({ type: 'SET_NAVBAR_TITLE', payload: i18n.t('upload.analyzeFile') });
  }, []);

  return (
    <>
      {(isFetchingPDF || isCreatingInvoice || isGettingCarriers) && <Loader />}
      <FileUploadPanel onShowChooseCarrierClick={handleShowChooseAnalyser} onFileChange={handleFileChange} />
      <CarrierSelectModal carriers={carriers} isModalVisible={isModalVisible} onApply={handleApply} onClose={handleClose} />
    </>
  );
};

UploadPage.propTypes = {
  history: Router.History.isRequired,
};

export default UploadPage;
