import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

const Toggle = ({ name, label, onToggle, className, isChecked }) => {
  const [value, setValue] = useState(false);

  const handleCheck = useCallback(
    e => {
      setValue(e.target.checked);
      onToggle(e.target.checked);
    },
    [onToggle]
  );

  useEffect(() => setValue(isChecked), [isChecked]);

  return (
    <label className={`toggle ${className}`} htmlFor={name}>
      <input id={name} name={name} type="checkbox" className="toggle__input" checked={value} onChange={handleCheck} />
      <span className="toggle__label">{label}</span>
    </label>
  );
};

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
};

Toggle.defaultProps = {
  label: '',
  className: '',
  isChecked: false,
};

export default React.memo(Toggle);
