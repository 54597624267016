import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_MALBERT_ID;

const isMatching = () => false;

const headerRowText = [
  'NOM',
  'CLIENT',
  'NUMÉRO',
  'CLIENT',
  'ADRESSE',
  'PICK',
  'UP',
  'ADRESSE',
  'LIVRAISON',
  'MOUVEMENT',
  '(ANNÉE/MOIS',
  '/JOUR)',
  'MONTANT',
  'VENTE',
  '(SANS',
  'TAXE)',
  '(0,03%',
  'TL',
  '0,05',
  '%LTL)',
  'TOTAL',
];

const getRowType = row => {
  const parsedRow = row
    .join(' ')
    .toUpperCase()
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split(' ');

  if (_.intersection(parsedRow, headerRowText).length >= 15) return 'HEADER';
  if (row.length === 3 && _.startsWith(row[0], 'TOTAL')) return 'TOTAL_ROW';
  if (row.length === 1 && _.startsWith(row[0], 'Rapport de ventes')) return 'TITLE';
  if (row.length === 0) return 'EMPTY_ROW';
  if (row.length === 8) return 'POSSIBLE_DATA';

  return 'UNKNOWN';
};

const analyseData = rows => {
  const transports = [];
  let isDataRow = false;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER') {
      isDataRow = true;
    } else if (rowType === 'EMPTY_ROW' || rowType === 'TITLE' || rowType === 'TOTAL_ROW') {
      isDataRow = false;
    } else if (isDataRow && rowType === 'POSSIBLE_DATA') {
      transports.push({
        date: row[4],
        from: row[2],
        to: row[3],
        amount: formatAmount(row[5].toString().replace(',', '.')),
        client: row[0],
        currency: 'CAD',
        commissionType: row[6] === '0,03' ? 'TL' : 'LTL',
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
