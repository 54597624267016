import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_BESSETTE_BOUDREAU_ID;

const isMatching = rows => {
  return rows.find(row => {
    return row.find(d => d.includes('Bessette et Boudreau'));
  });
};

const headerRowText = [
  'ORIGINE',
  'DESTINATION',
  'TRANSFÉRÉ',
  'PROBILL',
  'DATE FACTURE EXPÉDITEUR',
  'CONSIGNATAIRE',
  'TRANSPORT',
  'AUTRES FRAIS',
  'TOTAL',
  'DE',
  'À',
];

// ****
const months = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUIN', 'JUIL', 'AOU', 'SEP', 'OCT', 'DEC'];

const formatDate = date => {
  const [day, month, year] = date.trim().split('-');

  const monthIndex = months.findIndex(m => m.includes(_.deburr(month).toUpperCase()));

  return `${year}-${String(monthIndex + 1).padStart(2, '0')}-${day}`;
};

const getRowType = row => {
  const parsedRow = row
    .join(' ')
    .toUpperCase()
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split(' ');

  if (_.intersection(parsedRow, headerRowText).length > 5) return 'HEADER';
  if (row.find(data => data.includes('Total client'))) return 'TOTAL_CLIENT';
  if (row.find(data => data.includes('TOTAL'))) return 'TOTAL';
  if (row.length === 9) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = rows => {
  let client = '';
  const transports = [];

  rows.forEach((row, i) => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER' && rows[i + 1].length === 1) {
      [client] = rows[i + 1];
    } else if (rowType === 'DATA') {
      transports.push({
        client,
        poNumber: row[0],
        from: row[2],
        to: row[3],
        amount: formatAmount(row[4]),
        date: formatDate(row[1]),
        currency: 'CAD',
        commissionType: 'TL',
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
