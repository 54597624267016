import React, { useState, useCallback } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { Carrier } from '../../types';
import { Modal } from '../common';
import { SelectField } from '../common/fields';

const CarrierSelectModal = ({ carriers, isModalVisible, onApply, onClose }) => {
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [hasError, setHasError] = useState(false);

  const handleCarrierSelect = useCallback(carrier => {
    setSelectedCarrier(carrier);
    if (carrier) setHasError(false);
  }, []);

  const handleApply = useCallback(() => {
    if (selectedCarrier) onApply(selectedCarrier);
    else setHasError(true);
  }, [selectedCarrier]);

  const handleClose = useCallback(() => {
    setSelectedCarrier(null);
    setHasError(false);
    onClose();
  }, []);

  return (
    <Modal isVisible={isModalVisible} size="small">
      <header className="heading">{i18n.t('upload.chooseCarrier')}</header>

      <div className="panel__section">
        <SelectField
          name="zohoClient"
          options={carriers}
          onChange={handleCarrierSelect}
          className={`input__select -in-form ${hasError ? '-error' : ''}`}
          labelKey="contactName"
          valueKey="contactId"
        />

        <div className="panel__actions">
          <button type="button" className="button button__secondary" onClick={handleClose}>
            {i18n.t('button.cancel')}
          </button>
          <button type="button" className="button button__primary" onClick={handleApply}>
            {i18n.t('button.continue')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

CarrierSelectModal.propTypes = {
  carriers: PropTypes.arrayOf(Carrier),
  isModalVisible: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CarrierSelectModal.defaultProps = {
  carriers: [],
  isModalVisible: false,
};

export default React.memo(CarrierSelectModal);
