import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';

const TextField = ({
  fieldValue,
  name,
  label,
  children,
  className,
  disabled,
  parser,
  formatter,
  onBlur,
  onBlurChange,
  inputClassName,
  blurOnEnter,
  ...inputProps
}) => {
  const [value, setValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    e => {
      const formValue = parser ? parser(e.target.value) : e.target.value;

      // A parser will return undefined on a failure, null or a empty string if the value is empty
      if (formValue !== undefined) {
        setValue(e.target.value);

        if (formatter) setFormattedValue(formatter(formValue));
      }
    },
    [onBlurChange]
  );

  const handleKeyPress = useCallback(e => {
    if (blurOnEnter && e.key === 'Enter') document.getElementById(name).blur();
  }, []);

  const handleFocus = useCallback(() => setIsFocused(true), []);

  const handleBlur = useCallback(() => {
    const formValue = parser ? parser(value) : value;
    onBlurChange(formValue);
    setIsFocused(false);
    onBlur();
  }, [value]);

  useEffect(() => {
    if (isFocused) return;

    const parsedValue = parser ? parser(value) : value;

    if (fieldValue !== parsedValue) {
      const finalValue = fieldValue !== null && fieldValue !== undefined ? String(fieldValue) : '';
      setValue(finalValue);

      if (formatter) setFormattedValue(formatter(finalValue));
    }
  }, [fieldValue, value]);

  useEffect(() => {
    if (!isFocused && parser && value) {
      setValue(parser(value));
    }
  }, [isFocused]);

  const inputValue = formattedValue && formatter && !isFocused ? formattedValue : value;

  return (
    <div className={`input ${className}`}>
      {label && (
        <label className="input__label" htmlFor={name}>
          {label}
        </label>
      )}

      <input
        className={`input__field ${disabled ? '-disabled ' : ''}${inputClassName || ''}`}
        type="text"
        autoComplete="off"
        id={name}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        disabled={disabled}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        {...inputProps}
      />
    </div>
  );
};

TextField.propTypes = {
  children: Children,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onBlurChange: PropTypes.func,
  blurOnEnter: PropTypes.bool,
};

TextField.defaultProps = {
  children: undefined,
  fieldValue: undefined,
  className: '',
  inputClassName: '',
  disabled: false,
  label: '',
  formatter: undefined,
  parser: undefined,
  readOnly: false,
  onBlurChange: () => undefined,
  onBlur: () => undefined,
  blurOnEnter: false,
};

export default React.memo(TextField);
