import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_JGRS_BOUDREAULT_ID;

const isMatching = () => false;

const cleanSheet = sheet => {
  return sheet.filter(r => {
    const rowWithoutEmpty = _.compact(r);

    if (rowWithoutEmpty.length === 1) {
      const rowWithoutSpaces = rowWithoutEmpty[0].replace(/\s/g, '');

      if (rowWithoutSpaces === '-$') return false;
    }

    return true;
  });
};

const headerRowText = ['NOM', 'DATE', 'VENTE'];

const getRowType = row => {
  const matchingHeaders = row.filter(data => headerRowText.find(header => data.toUpperCase().includes(header)));

  if (matchingHeaders.length >= 2) return 'HEADER';
  if (row.length === 1) return 'CLIENT';

  if (row.length >= 3 && row.find(r => r.toUpperCase().includes('TOTAL'))) return 'TOTAL';
  if (row.length >= 3) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const sheets = Object.values(data);
  const transports = [];
  let lastClient = '';

  sheets.forEach(sheet => {
    const rows = cleanSheet(sheet);

    rows.forEach(row => {
      const rowType = getRowType(row);
      if (rowType === 'CLIENT') {
        // eslint-disable-next-line prefer-destructuring
        lastClient = row[0];
      } else if (rowType === 'DATA') {
        transports.push({
          description: row[0],
          date: row[1],
          amount: formatAmount(row[2]),
          client: lastClient,
          currency: 'CAD',
          commissionType: 'TL',
        });
      }
    });
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
