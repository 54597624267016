import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

import { getMostOccurringMonth } from '../momentHelpers';

const carrierId = process.env.REACT_APP_GROUPE_AVANTAGE_LOGISTIQUES_ID;

const isMatching = () => false;

const cleanSheet = sheet => {
  return sheet.filter(r => {
    const rowWithoutEmpty = _.compact(r);

    if (rowWithoutEmpty.length === 1) {
      const rowWithoutSpaces = rowWithoutEmpty[0].replace(/\s/g, '');

      if (rowWithoutSpaces === '-$') return false;
    }

    return true;
  });
};

const firstHeaderRowText = [
  'DATE',
  'NB ARRÊT',
  'KM FACTURÉ',
  'ARRÊT',
  'KM',
  'TOTAL AVANT SURCHARGE',
  'SURCHARGE',
  'TOTAL INCLUANT SURCHARGE',
  'COMMISSION',
];

const secondHeaderRowText = ['# PRO', 'DATE ', 'CLIENT', 'ORIGINE / DESTINATION', 'TAUX', 'COMMISSION'];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));
  const rowWithoutEmpty = parsedRow.filter(data => data !== '');

  const rowWithPercent = rowWithoutEmpty.find(data => data.includes('%'));

  if (rowWithPercent) return 'RATE';

  const matchingFirstHeaders = rowWithoutEmpty.filter(data => firstHeaderRowText.find(header => data.toUpperCase().includes(header)));

  if (matchingFirstHeaders.length >= 7) return 'FIRST_HEADER';

  const matchingSecondHeaders = rowWithoutEmpty.filter(data => secondHeaderRowText.find(header => data.toUpperCase().includes(header)));

  if (matchingSecondHeaders.length >= 4) return 'SECOND_HEADER';

  if (rowWithoutEmpty.length === 6 || rowWithoutEmpty.length === 5 || rowWithoutEmpty.length >= 8) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const sheets = Object.values(data);
  const transports = [];

  const subHeaderRowIndex = sheets[0].findIndex(row => {
    const formattedRow = row.map(r => r.toUpperCase());
    return formattedRow.includes('MOIS') && formattedRow.includes('TOTAL');
  });

  const clientsRow = sheets[0][subHeaderRowIndex - 1];
  let lastClient;
  let lastRate;

  sheets.forEach((sheet, i) => {
    if (i !== 0) {
      if (clientsRow[i]) {
        lastClient = clientsRow[i];
      }

      const rows = cleanSheet(sheet);
      let sheetType;

      rows.forEach(row => {
        const rowType = getRowType(row);

        if (rowType === 'FIRST_HEADER') {
          sheetType = 'FIRST';
        } else if (rowType === 'SECOND_HEADER') {
          sheetType = 'SECOND';
        } else if (rowType === 'RATE') {
          const rateText = row.find(d => d.includes('%'));
          lastRate = Number(rateText.replace('%', '').trim()) / 100;
        } else if (rowType === 'DATA') {
          if (i === 1 && sheetType === 'FIRST') {
            transports.push({
              client: lastClient,
              description: row[0],
              amount: formatAmount(row[8]),
              currency: 'CAD',
              rate: lastRate,
            });
          } else if (sheetType === 'FIRST') {
            transports.push({
              client: lastClient,
              description: row[0],
              amount: formatAmount(row[6]),
              currency: 'CAD',
              rate: lastRate,
            });
          } else if (sheetType === 'SECOND') {
            transports.push({
              client: lastClient,
              description: row[3],
              date: row[1],
              amount: formatAmount(row[4]),
              currency: 'CAD',
              rate: lastRate,
            });
          }
        }
      });
    }
  });

  const transportsWithDate = transports.filter(t => t.date);
  const mostOccurringMonth = getMostOccurringMonth(transportsWithDate.map(t => t.date));
  const dateInTransport = transportsWithDate.find(t => t.date.substring(5, 7) === mostOccurringMonth).date;
  const fallbackDate = `${dateInTransport.substring(0, 4)}-${dateInTransport.substring(5, 7)}-01`;

  return transports.map(t => {
    return !t.date ? { ...t, date: fallbackDate } : t;
  });
};

export default { carrierId, isMatching, analyseData, metaData: { hasCustomRates: true } };
