import _ from 'lodash';
import moment from 'moment';
import { formatAmount } from '../analyserHelpers';

import { getLastDayOfMonth } from '../momentHelpers';

const isMatching = (name = '') => sheets => {
  const rowsWithoutEmpty = sheets[0].filter(rows => {
    if (!_.isArray(rows)) return false;

    const rowWithoutEmpty = rows.filter(data => data !== '');
    return rowWithoutEmpty.length > 0;
  });

  if (rowsWithoutEmpty.length <= 1) return false;

  const rowsWithPossibleName = [rowsWithoutEmpty[0], rowsWithoutEmpty[1]];

  return rowsWithPossibleName.find(r => r.find(d => d.toUpperCase().includes(name.toUpperCase())));
};

const possibleUSDCurrency = ['USD', 'US', 'USA'];
const possibleMonths = ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'];

const headerRowText = [
  'NOM DU CLIENT',
  '# CLIENT',
  'ADRESSE DU PICK UP PICK UP ADRESS',
  'ADRESSE DE LIVRAISON DELIVERY ADRESS',
  'DATE  ANNÉE/MOIS/JOUR YEAR/MONTH/DAY',
  'DEVISE',
  'MONTANT  DE LA VENTE (SANS TAXE) TOTAL WITHOUT TAX',
  'COMMISSION (0,03% TL OU 0,05 %LTL)',
  'TOTAL',
];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));

  const rowWithoutEmpty = parsedRow.filter(data => data !== '');

  if (_.intersection(parsedRow, headerRowText).length > 5) return 'HEADER';
  if (parsedRow.find(text => text.includes('TOTAL'))) return 'TOTAL_ROW';
  if (rowWithoutEmpty.length === 1 && rowWithoutEmpty[0] === '0') return 'EMPTY_TOTAL_ROW';
  if (parsedRow.length >= 7) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const sheets = Object.values(data);
  let rows;

  if (sheets.length > 1) {
    const sheetNames = Object.keys(data);
    const previousMonth = moment().subtract(1, 'months');
    const year = previousMonth.year();
    const month = possibleMonths[previousMonth.month()];

    const sheetName = sheetNames.find(tn => {
      const cleanName = _.deburr(tn.toUpperCase()).replace(' ', '');

      return cleanName === `${month}${year}`;
    });

    rows = sheetName ? data[sheetName] : sheets[0];
  } else {
    [rows] = sheets;
  }

  let transports = [];
  let fallbackMonth;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'DATA') {
      const amount = formatAmount(row[6]);

      if (amount) {
        let client = row[0];

        if (!client) client = _.get(_.findLast(transports, t => t.client), 'client');

        transports.push({
          client,
          poNumber: row[1],
          from: row[2],
          to: row[3],
          date: row[4],
          currency: possibleUSDCurrency.includes(row[5].toUpperCase()) ? 'USD' : 'CAD',
          amount,
          commissionType: row[7].includes('0.05') || row[7].includes('0,05') ? 'LTL' : 'TL',
        });
      }
    } else if (rowType === 'UNKNOWN') {
      const parsedRow = _.deburr(row.join(' ').toUpperCase());
      const possibleMonth = possibleMonths.findIndex(m => parsedRow.includes(m));

      if (possibleMonth && possibleMonth !== -1 && !fallbackMonth) fallbackMonth = possibleMonth;
    }
  });

  const transportWithDate = transports.find(t => t.date);

  if (!transportWithDate && fallbackMonth) {
    const firstDayOfFallbackMonth = `${new Date().getFullYear()}-${String(fallbackMonth + 1).padStart(2, '0')}-01`;
    const fallbackDate = getLastDayOfMonth(firstDayOfFallbackMonth);

    transports = transports.map(t => ({ ...t, date: fallbackDate }));
  }

  return transports;
};

export default { analyseData, isMatching };
