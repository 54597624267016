import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Switch = ({ isActive, options, name, onChange, className, disabled }) => {
  const [value, setValue] = useState(isActive);

  const handleSelect = useCallback(
    e => {
      setValue(!value);
      onChange(_.get(options, e.target.checked ? 1 : 0, null));
    },
    [value, onChange, options]
  );

  useEffect(() => setValue(isActive), [isActive]);

  return (
    <label className={`switch ${options.length === 1 ? '-single' : ''} ${className}`} htmlFor={name}>
      <input type="checkbox" className="switch__field" id={name} name={name} checked={value} onChange={handleSelect} disabled={disabled} />
      <div className="switch__track">
        {options.map(o => {
          return (
            <span className="switch__label" key={o.key}>
              {o.label}
            </span>
          );
        })}
      </div>
    </label>
  );
};

Switch.propTypes = {
  isActive: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Switch.defaultProps = {
  isActive: false,
  onChange: () => undefined,
  className: '',
  disabled: false,
};

export default React.memo(Switch);
