import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_JAGUAR_ID;

const isMatching = () => false;

const formatDate = date => {
  const parts = date.split('/');

  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

const headerRowText = [
  'NOM',
  'CLIENT',
  'NUMERO',
  'VOYAGE',
  'CUSTOMER',
  'ADRESSE',
  'PICK',
  'ADRESS',
  'LIVRAISON',
  'DELIVERY',
  'DATE',
  'MOUVEMENT',
  'DEVISE',
  'CURRENCY',
  'MONTANT',
  'VENTE',
  'TAXE',
  'TOTAL',
  'TAX',
  'COMMISSION',
  'TOTAL',
];

const getRowType = row => {
  const parsedRow = _.deburr(
    row
      .join(' ')
      .toUpperCase()
      .replace(/(\r\n|\n|\r)/gm, ' ')
  ).split(' ');

  if (_.intersection(parsedRow, headerRowText).length >= 5) return 'HEADER';

  const rowWithoutEmpty = _.compact(row);
  const hasTotal = rowWithoutEmpty.find(data => data.toUpperCase().includes('TOTAL'));

  if (rowWithoutEmpty.length < 9 && hasTotal) return 'TOTAL';

  if (row.length >= 9) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const rows = Object.values(data)[0];
  const transports = [];

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'DATA') {
      transports.push({
        client: row[0],
        from: row[3],
        to: row[4],
        date: formatDate(row[6]),
        amount: formatAmount(row[8]),
        currency: row[7].toUpperCase() === 'CAN' ? 'CAD' : 'USD',
        commissionType: 'TL',
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
