import React from 'react';

import { Children } from '../../../types';

const ListBody = ({ children }) => {
  return <ul>{children}</ul>;
};

ListBody.propTypes = {
  children: Children.isRequired,
};

export default React.memo(ListBody);
