const defaultControl = base => ({
  ...base,
  ':hover': { borderColor: '#c2c2c2' },
  backgroundColor: '#fff',
  borderBottom: 'none',
  borderRadius: '0.75rem',
  borderWidth: 0,
  boxShadow: '0.3125rem 0.3125rem 0.9375rem -0.3125rem #d8d8d8',
  height: 'auto',
  minHeight: 'auto',
  padding: '0.25rem',
  transition: 'border-color 125ms linear',
});

const mergeStyles = (base, styles = {}, overrides = {}) => ({
  ...base,
  ...styles,
  ...overrides,
});

const getSelectStyles = (overrides = {}) => ({
  clearIndicator: base => mergeStyles(base, { paddingRight: 0, ':hover': { cursor: 'pointer' } }, overrides.clearIndicator),
  control: defaultControl,
  indicatorSeparator: () => mergeStyles({ display: 'none' }),
  indicatorsContainer: base =>
    mergeStyles(
      base,
      {
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
      },
      overrides.indicatorsContainer
    ),
  input: base => mergeStyles(base, { margin: 0, padding: '0.125rem 0 0.25rem' }, overrides.input),
  menu: base => mergeStyles(base, { minWidth: '13.75rem' }, overrides.menu),
  multiValue: base => mergeStyles(base, { backgroundColor: '#dbe7ea', borderRadius: '0.625rem' }, overrides.multiValue),
  multiValueLabel: base => mergeStyles(base, { padding: '0.3125rem 0 0.25rem 0.625rem', paddingLeft: '0.625rem' }, overrides.multiValueLabel),
  multiValueRemove: base =>
    mergeStyles(
      base,
      {
        backgroundColor: '#dbe7ea',
        borderRadius: '0.625rem',
        color: '#aaaaaa',
        cursor: 'pointer',
      },
      overrides.multiValueRemove
    ),
  option: (base, { isDisabled, isFocused, isSelected }) =>
    mergeStyles(
      base,
      {
        backgroundColor: isFocused || isSelected ? '#efefef' : 'white',
        color: '#778488',
        cursor: 'pointer',
        opacity: isDisabled ? 0.6 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto',
      },
      overrides.option
    ),
  singleValue: base => mergeStyles(base, { margin: 0, padding: '0.2rem 0' }, overrides.singleValue),
  valueContainer: base => mergeStyles(base, { padding: '0.125rem 2rem 0 0' }, overrides.valueContainer),
});

export default getSelectStyles;
