import React, { useContext, useEffect } from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import AppContext from '../../contextes/AppContext';

const NotFoundPage = () => {
  const appContext = useContext(AppContext);

  useEffect(() => appContext.dispatch({ type: 'SET_NAVBAR_TITLE', payload: i18n.t('notFound.pageNotFoundTitle') }), []);

  return (
    <div className="layout__content">
      <div className="panel -medium -center -no-color">
        <div className="notfound">
          <div className="notfound__title">
            <span className="-black">4</span>
            <span className="-blue">0</span>
            <span className="-black">4</span>
          </div>
          <p className="notfound__content -subtitle">{i18n.t('notFound.pageNotFoundText')}</p>
          <Link to="/">
            <button type="button" className="button button__primary -margin-top -margin-bottom">
              {i18n.t('notFound.backToSafety')}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NotFoundPage);
