import moment from 'moment';
import _ from 'lodash';

export const isoDateFormat = 'YYYY-MM-DD';

export const toUtc = date => {
  return date
    ? moment(date)
        .utc()
        .format()
    : undefined;
};

export const toLocal = date => {
  return date ? moment(date).local() : undefined;
};

export const getToday = () => moment().format(isoDateFormat);

export const isDateValid = date => moment(date).isValid();

export const getLastDayOfMonth = date =>
  moment(date)
    .endOf('month')
    .format(isoDateFormat);

export const getUTCDate = date =>
  new Date(
    moment(date)
      .utc()
      .format()
  );

const possibleMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

export const getMostOccurringMonth = dates => {
  const numberOfTransportsByMonth = _.countBy(dates, date => date.substring(5, 7));
  let highestMonth = { month: 0, occurrence: 0 };

  _.forEach(numberOfTransportsByMonth, (occurrence, month) => {
    if (possibleMonths.includes(month) && occurrence > highestMonth.occurrence) highestMonth = { month, occurrence };
  });

  return highestMonth.month;
};
