import PropTypes from 'prop-types';

const Transport = PropTypes.shape({
  id: PropTypes.string,
  date: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currency: PropTypes.string,
  commissionType: PropTypes.string,
});

export default Transport;
