import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { List, ListHeader, ListHeaderCol, ListBody, ListFooter } from '../common/list';
import { moneyFormatter } from '../../helpers/fieldHelpers';
import { getCommissionsTotal } from '../../helpers/invoiceHelpers';
import Item from '../../types/Item';
import InvoiceSummaryRow from './InvoiceSummaryRow';

const zeroDollar = '0.00 $';

const InvoiceSummaryPanel = ({ errors, items, zohoInvoiceItems, onItemChange }) => {
  const total = getCommissionsTotal(items);

  return (
    <div className="panel__wrapper">
      <div className="header">
        <div className="header__left">
          <h3 className="heading">{i18n.t('carrierInvoice.invoicePreview')}</h3>
        </div>
      </div>

      <List>
        <ListHeader>
          <ListHeaderCol className="-x-8">{i18n.t('carrierInvoice.description')}</ListHeaderCol>
          <ListHeaderCol className="-x-4 -align-right">{i18n.t('carrierInvoice.quantity')}</ListHeaderCol>
          <ListHeaderCol className="-x-4 -align-right">{i18n.t('carrierInvoice.unitPrice')}</ListHeaderCol>
          <ListHeaderCol className="-x-4 -align-right">{i18n.t('carrierInvoice.total')}</ListHeaderCol>
        </ListHeader>

        <ListBody>
          {items.map((item, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <InvoiceSummaryRow item={item} errors={errors} zohoInvoiceItems={zohoInvoiceItems} onItemChange={onItemChange} key={i} />
            );
          })}
        </ListBody>

        <ListFooter className="-end">
          <p className="summary__item">{`${i18n.t('carrierInvoice.totalWithoutTaxes')}: ${moneyFormatter(total) || zeroDollar}`}</p>
        </ListFooter>
      </List>
    </div>
  );
};

InvoiceSummaryPanel.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      client: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.string,
    })
  ),
  items: PropTypes.arrayOf(PropTypes.shape({ description: PropTypes.string, quantity: PropTypes.number, rate: PropTypes.number })),
  zohoInvoiceItems: PropTypes.arrayOf(Item),
  onItemChange: PropTypes.func.isRequired,
};

InvoiceSummaryPanel.defaultProps = {
  errors: [],
  items: [],
  zohoInvoiceItems: [],
};

export default React.memo(InvoiceSummaryPanel);
