import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const isMatching = name => rows => {
  return rows.find(r =>
    r.find(data => {
      if (_.isArray(data)) {
        return data.find(d => d.toUpperCase().includes(name.toUpperCase()));
      }

      return data.toUpperCase().includes(name.toUpperCase());
    })
  );
};

const possibleUSDCurrency = ['USD', 'US', 'USA'];
const headerRowText = [
  'NOM DU CLIENT',
  'SHIPMENT ID #',
  'ADRESSE DU PICK UP PICK UP ADRESS',
  'ADRESSE DE LIVRAISON DELIVERY ADRESS',
  'DATE  ANNÉE/MOIS/JOUR YEAR/MONTH/DAY',
  'DEVISE ',
  'MONTANT  DE LA VENTE (SANS TAXE) TOTAL WITHOUT TAX',
  'COMMISSION (0,03% TL OU 0,05 %LTL)',
  'TOTAL',
];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));

  if (_.intersection(parsedRow, headerRowText).length > 5) return 'HEADER';
  if (parsedRow.find(text => text.includes('TOTAL'))) return 'TOTAL_ROW';
  if (row.length === 1 && _.startsWith(row[0], 'Rapport de ventes')) return 'TITLE';
  if ((row.length === 1 && (row[0] === '0,00' || row[0] === '0.00')) || row.length === 0) return 'EMPTY_ROW';
  if (row.length >= 7) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = rows => {
  const transports = [];

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'DATA') {
      const client = row[0] || _.get(_.findLast(transports, t => t.client), 'client');

      transports.push({
        client,
        poNumber: row[1],
        from: row[2],
        to: row[3],
        date: row[4],
        currency: possibleUSDCurrency.includes(row[5].toUpperCase()) ? 'USD' : 'CAD',
        amount: formatAmount(row[6]),
        commissionType: row[7].includes('0.05') || row[7].includes('0,05') ? 'LTL' : 'TL',
      });
    }
  });

  return transports;
};

export default { isMatching, analyseData };
