import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_CH_EXPRESS_ID;

const isMatching = sheets => {
  return sheets[0].find(r => {
    if (!_.isArray(r)) return false;

    return r
      .join(' ')
      .toUpperCase()
      .includes('CHEXPRESS');
  });
};

const months = ['JANVIER', 'FEVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOUT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DECEMBRE'];

const formatDate = date => {
  const parts = date.split(' ');
  const month = months.findIndex(m => m.includes(_.deburr(parts[1]).toUpperCase()));

  return `${parts[2]}-${String(month + 1).padStart(2, '0')}-${String(parts[0]).padStart(2, '0')}`;
};

const possibleCommissions = [3, 5];

const getCommissionType = (amount, commission) => {
  const commissionPercentage = 100 / (amount / commission);

  const closest = possibleCommissions.reduce((prev, curr) => {
    return Math.abs(curr - commissionPercentage) < Math.abs(prev - commissionPercentage) ? curr : prev;
  });

  return closest === 3 ? 'TL' : 'LTL';
};

const headerRowText = ['Date', 'Client', 'Voyage', 'Facturé', 'Montant'];

const getRowType = row => {
  const matchingHeaders = row.filter(data => headerRowText.find(header => data.includes(header)));

  if (matchingHeaders.length > 2) return 'HEADER';
  if (row.find(data => data.includes('Total')) && _.compact(row).length < 4) return 'TOTAL';

  return 'UNKNOWN';
};

const analyseData = data => {
  const rows = Object.values(data)[0];
  const transports = [];
  let isDataRow = false;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER') {
      isDataRow = true;
    } else if (rowType === 'TOTAL') {
      isDataRow = false;
    } else if (isDataRow) {
      const amount = formatAmount(row[3]);

      transports.push({
        date: formatDate(row[0]),
        client: row[1],
        description: `Voyage # ${row[2]}`,
        amount,
        currency: 'CAD',
        commissionType: getCommissionType(amount, formatAmount(row[4])),
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
