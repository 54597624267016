import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';

const CarrierInvoiceSuccessPanel = ({ invoiceId }) => {
  return (
    <div className="panel -small -center">
      <div className="panel__content -center">
        <i className="panel__content-icon -success">
          <SuccessIcon />
        </i>

        <h3 className="panel__content-item heading -bold">{i18n.t('carrierInvoice.success')}</h3>
        <h3 className="panel__content-item heading -small">{i18n.t('carrierInvoice.invoiceSent')}</h3>

        <div className="panel__content-item -more-margin">
          <Link to="/" className="button button__secondary">
            {i18n.t('carrierInvoice.analyseAnotherFile')}
          </Link>

          <a
            href={`https://books.zoho.com/app#/invoices/${invoiceId}`}
            target="_blank"
            rel="noopener noreferrer"
            type="button"
            className="button button__secondary">
            {i18n.t('carrierInvoice.viewInvoice')}
          </a>
        </div>
      </div>
    </div>
  );
};

CarrierInvoiceSuccessPanel.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default React.memo(CarrierInvoiceSuccessPanel);
