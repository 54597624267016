import React, { useEffect, useContext } from 'react';
import i18n from 'i18next';

import AppContext from '../contextes/AppContext';
import { Router } from '../types';
import CarrierInvoiceSuccessPanel from '../components/carrierInvoice/InvoiceCreationSuccessPanel';

const InvoiceCreationSuccessPage = ({ match }) => {
  const appContext = useContext(AppContext);

  useEffect(() => {
    appContext.dispatch({ type: 'SET_NAVBAR_TITLE', payload: i18n.t('carrierInvoice.invoiceCreated') });
  }, []);

  return (
    <div className="layout__content">
      <CarrierInvoiceSuccessPanel invoiceId={match.params.id} />
    </div>
  );
};

InvoiceCreationSuccessPage.propTypes = {
  match: Router.Match.isRequired,
};

export default InvoiceCreationSuccessPage;
