import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Transport } from '../../types';
import {
  toNumber,
  numberFormatter,
  toMoneyOrText,
  moneyFormatter,
  moneyOrTextFormatter,
  percentageFormatter,
  toPercentage,
  isNaN,
  isZero,
  isNegative,
} from '../../helpers/fieldHelpers';
import { List, ListCol, ListHeader, ListHeaderCol, ListRow, ListBody, ListFooter, ListActionBar } from '../common/list';
import { Switch, Toggle } from '../common';
import { TextField, EditableTextField } from '../common/fields';

const zeroDollar = '0.00 $';

const TransportList = ({
  ltlCommission,
  tlCommission,
  exchangeRate,
  transports,
  onValueChange,
  onGlobalCurrencyChange,
  onGlobalCommissionChange,
  onExchangeRateChange,
  onExchangeRateToggle,
  isCommissionSwitchActive,
  isCurrencySwitchActive,
  hasTransportInUSD,
  hasCustomRates,
}) => {
  const [isFromToEmpty, setIsFromToEmpty] = useState(false);
  const currencyOptions = [{ key: 'CAD', label: i18n.t('currency.CAD') }, { key: 'USD', label: i18n.t('currency.USD') }];
  const commissionOptions = [{ key: 'TL', label: i18n.t('commission.TL') }, { key: 'LTL', label: i18n.t('commission.LTL') }];
  let cadAmountTotal = 0;
  let commissionTotal = 0;

  const [isExchangeRateOn, setIsExchangeRateOn] = useState(false);

  const handleExchangeRateToggle = useCallback(
    isOn => {
      setIsExchangeRateOn(isOn);
      onExchangeRateToggle(isOn);
    },
    [onExchangeRateToggle]
  );

  useEffect(() => {
    if (hasTransportInUSD) setIsExchangeRateOn(true);
  }, [hasTransportInUSD]);

  useEffect(() => setIsFromToEmpty(transports.find(t => t.description)), [transports]);

  return (
    <List>
      <ListActionBar>
        <div className="list__action-item">
          <Toggle
            className="-margin-right"
            name="useExchangeRate"
            label={i18n.t('carrierInvoice.useExchangeRate')}
            onToggle={handleExchangeRateToggle}
            isChecked={isExchangeRateOn}
          />

          {isExchangeRateOn && (
            <div className="input__container">
              {i18n.t('currency.oneUSD')}
              <TextField
                name="clientCurrency"
                className="-small"
                inputClassName="-text-center"
                onBlurChange={onExchangeRateChange}
                formatter={numberFormatter}
                parser={toNumber}
                fieldValue={exchangeRate}
              />
              {i18n.t('currency.CAD')}
            </div>
          )}
        </div>

        {isExchangeRateOn && (
          <div className="list__action-item">
            <span className="-action-label">{i18n.t('carrierInvoice.clientCurrency')}</span>
            <Switch
              name="globalCurrency"
              className="-small"
              isActive={isCurrencySwitchActive}
              options={currencyOptions}
              onChange={onGlobalCurrencyChange}
            />
          </div>
        )}

        {!hasCustomRates && (
          <div className="list__action-item">
            <span className="-action-label">{i18n.t('carrierInvoice.clientCommission')}</span>
            <Switch
              name="globalCommission"
              className="-small"
              isActive={isCommissionSwitchActive}
              options={commissionOptions}
              onChange={onGlobalCommissionChange}
            />
          </div>
        )}
      </ListActionBar>

      <ListHeader>
        <ListHeaderCol className={isExchangeRateOn ? '-x-2' : '-x-3'}>{i18n.t('carrierInvoice.date')}</ListHeaderCol>

        {isFromToEmpty && <ListHeaderCol className={isExchangeRateOn ? '-x-6' : '-x-8'}>{i18n.t('carrierInvoice.description')}</ListHeaderCol>}
        {!isFromToEmpty && (
          <>
            <ListHeaderCol className={isExchangeRateOn ? '-x-3' : '-x-4'}>{i18n.t('carrierInvoice.from')}</ListHeaderCol>
            <ListHeaderCol className={isExchangeRateOn ? '-x-3' : '-x-4'}>{i18n.t('carrierInvoice.to')}</ListHeaderCol>
          </>
        )}

        <ListHeaderCol className="-x-2 -align-right">
          {isExchangeRateOn ? i18n.t('carrierInvoice.originalAmount') : i18n.t('carrierInvoice.amount')}
        </ListHeaderCol>
        {!isExchangeRateOn && <ListHeaderCol className="-x-1" />}
        {isExchangeRateOn && <ListHeaderCol className="-x-2 -align-right">{i18n.t('carrierInvoice.amountCAD')}</ListHeaderCol>}
        {isExchangeRateOn && <ListHeaderCol className="-x-1" />}
        {isExchangeRateOn && <ListHeaderCol className="-x-3">{i18n.t('carrierInvoice.currency')}</ListHeaderCol>}
        {!isExchangeRateOn && <ListHeaderCol className="-x-1" />}
        <ListHeaderCol className={isExchangeRateOn ? '-x-2' : '-x-3'}>
          {i18n.t(hasCustomRates ? 'carrierInvoice.commisionRate' : 'carrierInvoice.commisionType')}
        </ListHeaderCol>
        <ListHeaderCol className="-x-2 -align-right">{i18n.t('carrierInvoice.commission')}</ListHeaderCol>
      </ListHeader>

      <ListBody>
        {transports.map((transport, i) => {
          const transportPercentRate = (transport.rate * 100).toFixed(5);
          const commission = transport.commissionType === 'TL' ? tlCommission : ltlCommission;
          const rate = hasCustomRates ? transport.rate : commission;
          const rowExchangeRate = transport.currency === 'USD' ? Number(exchangeRate) : 1;
          const rowExchangeRateMultiplier = isExchangeRateOn ? rowExchangeRate : 1;
          const transportCADAmount = Number(transport.amount) * rowExchangeRateMultiplier;
          const transportCommission = Number(transportCADAmount * rate);

          cadAmountTotal += transportCADAmount;
          commissionTotal += transportCommission;

          return (
            <ListRow key={transport.id}>
              <ListCol className={isExchangeRateOn ? '-x-2 -on-top' : '-x-3 -on-top'}>{transport.date}</ListCol>

              {isFromToEmpty && (
                <ListCol className={`${isExchangeRateOn ? '-x-6 -on-top' : '-x-8 -on-top'} -ellipsis`}>{transport.description || ''}</ListCol>
              )}
              {!isFromToEmpty && (
                <>
                  <ListCol className={`${isExchangeRateOn ? '-x-3 -on-top' : '-x-4 -on-top'} -ellipsis`}>{transport.from}</ListCol>
                  <ListCol className={`${isExchangeRateOn ? '-x-3 -on-top' : '-x-4 -on-top'} -ellipsis`}>{transport.to}</ListCol>
                </>
              )}

              <ListCol className="-x-2 -align-right">
                <EditableTextField
                  name={`amount-${transport.id}`}
                  className="-align-right"
                  editClassName="-align-right"
                  fieldValue={transport.amount || 0}
                  formatter={moneyOrTextFormatter}
                  parser={toMoneyOrText}
                  validate={[isNaN, isZero, isNegative]}
                  onChange={newValue => onValueChange(transport.id, 'amount', newValue)}
                />
              </ListCol>

              {!isExchangeRateOn && <ListCol className="-x-1" />}

              {isExchangeRateOn && <ListCol className="-x-2 -align-right -on-top">{`${moneyFormatter(transportCADAmount) || zeroDollar}`}</ListCol>}

              {isExchangeRateOn && <ListCol className="-x-1" />}

              {isExchangeRateOn && (
                <ListCol className="-x-3">
                  <Switch
                    name={`currency${i}`}
                    className="-small"
                    options={currencyOptions}
                    isActive={transport.currency === 'USD'}
                    onChange={({ key }) => onValueChange(transport.id, 'currency', key)}
                  />
                </ListCol>
              )}
              {!isExchangeRateOn && <ListCol className="-x-1" />}
              <ListCol className={isExchangeRateOn ? '-x-2' : '-x-3'}>
                {hasCustomRates && (
                  <EditableTextField
                    name={`rate-${transport.id}`}
                    fieldValue={transportPercentRate}
                    formatter={percentageFormatter}
                    parser={toPercentage}
                    onChange={newValue => onValueChange(transport.id, 'rate', newValue)}
                  />
                )}
                {!hasCustomRates && (
                  <Switch
                    name={`commission${i}`}
                    className="-small"
                    options={commissionOptions}
                    isActive={transport.commissionType === 'LTL'}
                    onChange={({ key }) => onValueChange(transport.id, 'commissionType', key)}
                  />
                )}
              </ListCol>
              <ListCol className="-x-2 -align-right -on-top">{`${moneyFormatter(transportCommission) || zeroDollar}`}</ListCol>
            </ListRow>
          );
        })}
      </ListBody>

      <ListFooter className="-end">
        <p className="summary__item">{`${i18n.t('carrierInvoice.totalAmount')}: ${moneyFormatter(cadAmountTotal) || zeroDollar}`}</p>
        <p className="summary__item">{`${i18n.t('carrierInvoice.totalCommission')}: ${moneyFormatter(commissionTotal) || zeroDollar}`}</p>
      </ListFooter>
    </List>
  );
};

TransportList.propTypes = {
  ltlCommission: PropTypes.number.isRequired,
  tlCommission: PropTypes.number.isRequired,
  exchangeRate: PropTypes.number,
  transports: PropTypes.arrayOf(Transport),
  onValueChange: PropTypes.func.isRequired,
  onGlobalCurrencyChange: PropTypes.func.isRequired,
  onGlobalCommissionChange: PropTypes.func.isRequired,
  onExchangeRateChange: PropTypes.func.isRequired,
  onExchangeRateToggle: PropTypes.func.isRequired,
  isCommissionSwitchActive: PropTypes.bool,
  isCurrencySwitchActive: PropTypes.bool,
  hasTransportInUSD: PropTypes.bool,
  hasCustomRates: PropTypes.bool,
};

TransportList.defaultProps = {
  exchangeRate: 0,
  transports: [],
  isCommissionSwitchActive: false,
  isCurrencySwitchActive: false,
  hasTransportInUSD: false,
  hasCustomRates: false,
};

export default React.memo(TransportList);
