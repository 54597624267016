import PropTypes from 'prop-types';

const Report = PropTypes.shape({
  carrierId: PropTypes.string,
  carrierName: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  commissionAmount: PropTypes.number,
  date: PropTypes.date,
});

export default Report;
