export default {
  globalError: 'An error occurred, please try again!',
  unableToAnalyseReport: 'Unable to analyse report. It is possible that the report format has changed or the wrong carrier has been chosen.',
  button: {
    apply: 'Apply',
    continue: 'Continue',
    cancel: 'Cancel',
    delete: 'Delete'
  },
  currency: {
    oneUSD: '1 USD = ',
    CAD: 'CAD',
    USD: 'USD'
  },
  commission: {
    TL: 'TL',
    LTL: 'LTL'
  },
  country: {
    all: 'All',
    canada: 'Canada',
    usa: 'USA'
  },
  selectPlaceholder: 'Select a value',
  notFound: {
    backToSafety: 'Back to safety',
    pageNotFoundText: 'Oops, the page you\'re looking for doesn\'t exist.',
    pageNotFoundTitle: 'Page not found',
  },
  navbar: {
    login: 'Login',
    logout: 'Logout',
    menu: 'Menu',
    invoiceHistory: 'Invoice history',
    invoice: 'Invoice'
  },
  upload: {
    browseFile: 'Click to Browse',
    chooseCarrierFirst: 'Choose carrier first',
    dragAndDrop: 'Drag and Drop',
    or: 'Or',
    analyzeFile: 'Analyze a file',
    chooseCarrier: 'Please choose the report carrier',
    uploadFile: 'Upload a file',
  },
  report: {
    carrierWithoutInvoice: 'Carrier(s) without invoice',
    allMonths: 'All months',
    line: 'Line'
  },
  carrierInvoice: {
    amountCAD: 'CAD Amount',
    anount: 'Amount',
    carrier: 'Carrier',
    carriers: 'Carriers',
    client: 'Client',
    clients: 'Clients',
    country: 'Country',
    date: 'Date',
    dateRange: 'Date Range',
    deleteModalMessage: 'Do you really want to delete this invoice?',
    description: 'Description',
    endDate: 'End Date',
    from: 'From',
    to: 'To',
    amount: 'Amount',
    originalAmount: 'Original Amount',
    currency: 'Currency',
    commisionType: 'Commission Type',
    commission: 'Commision',
    noDate: 'No available date for this report',
    reportDate: 'Report date',
    reports: 'Reports',
    total: 'Total',
    totals: 'Totals',
    send: 'Send',
    startDate: 'Start Date',
    totalAmount: 'Total amount',
    totalCommission: 'Total commission',
    useExchangeRate: 'Use exchange rate',
    clientCurrency: 'Client currency',
    clientCommission: 'Client commission',
    sendToZoho: 'Send to Zoho',
    success: 'Success!',
    invoiceSent: 'The invoice was sent to Zoho',
    analyseAnotherFile: 'Analyse another file',
    viewInvoice: 'View invoice',
    invoiceCreated: 'INVOICE CREATED',
    invoicePreview: 'Invoice Preview',
    commissionRate: 'Commission Rate',
    totalWithoutTaxes: 'Total (without taxes)',
    state: 'State',
    sent: 'Sent',
    pending: 'Pending',
    noInvoiceFound: 'No invoice found',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    updateZohoInvoice: 'Update Zoho Invoice',
    updateZohoReason: 'Updated via application',
    reupload: 'Reupload',
    commisionRate: 'Commission Rate'
  }
};
