import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_GILMYR_ID;

const isMatching = () => false;

const parseWeight = weightText => {
  if (weightText === '') return NaN;

  return Number(
    weightText
      .toUpperCase()
      .replace('LBS', '')
      .replace(' ', '')
  );
};

const getCommissionType = weightText => {
  const weight = parseWeight(weightText);

  return weight >= 10000 ? 'TL' : 'LTL';
};

const headerRowText = [
  'VENDEUR',
  'CLIENT',
  'DATE FACTURE',
  'FACTURE',
  'TYPE VOYAGE',
  'VILLE ORIGINE',
  'VILLE DESTINATION',
  'PROV',
  'POIDS',
  'MONTANT',
  'MARCHANDISE',
  'FRAIS SUPP.',
];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));
  const rowWithoutEmpty = parsedRow.filter(data => data !== '');
  const matchingHeaders = rowWithoutEmpty.filter(data => headerRowText.find(header => data.toUpperCase().includes(header)));

  if (matchingHeaders.length >= 2) return 'HEADER';
  if (rowWithoutEmpty.length === 1) return 'POSSIBLE_CLIENT';

  const amount = formatAmount(row[9]);
  const weight = parseWeight(row[8]);

  if (!Number.isNaN(amount) && !Number.isNaN(weight)) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const rows = Object.values(data)[0];
  const transports = [];
  let isHeaderPassed = false;
  let lastClient;

  rows.forEach(row => {
    const rowType = getRowType(row);

    if (rowType === 'HEADER') {
      isHeaderPassed = true;
    } else if (rowType === 'POSSIBLE_CLIENT' && isHeaderPassed) {
      // eslint-disable-next-line prefer-destructuring
      lastClient = row[1];
    } else if (rowType === 'DATA') {
      transports.push({
        client: lastClient,
        date: row[2],
        from: row[5],
        to: row[6],
        currency: 'CAD',
        amount: formatAmount(row[9]),
        commissionType: getCommissionType(row[8]),
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
