import _ from 'lodash';
import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_TRANSPORT_JEAN_FRANCOIS_ID;

const isMatching = () => false;

const headerRowText = ['RAPPORT DE VENTE', 'TRANSGLOBE'];

const getRowType = row => {
  const parsedRow = row.map(r => r.toUpperCase().replace(/(\r\n|\n|\r)/gm, ' '));

  if (_.intersection(parsedRow, headerRowText).length >= 1) return 'HEADER';
  if (row.find(data => data.toUpperCase().includes('TOTAL') || data.toUpperCase().includes('COMMISSION'))) return 'TOTAL_ROW';
  if (row.length === 1) return 'CLIENT_WITHOUT_SELL';
  if (row.length >= 2) return 'DATA';

  return 'UNKNOWN';
};

const analyseData = data => {
  const transports = [];

  data.forEach((row, i) => {
    const rowType = getRowType(row);

    if (rowType === 'DATA') {
      const amount = row[1].includes('%') ? formatAmount(row[2]) : formatAmount(row[1]);
      transports.push({
        description: `Ligne ${i}`,
        amount,
        client: row[0].replace('%', '').trim(),
        currency: 'CAD',
        commissionType: 'TL',
      });
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
