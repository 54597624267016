import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { Children } from '../../types';

const FileUpload = ({ label, children, className, disabled, onChange, ...inputProps }) => {
  const onDrop = useCallback(
    file => {
      if (onChange) onChange(file);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'application/pdf, .xlsx, .xls',
    multiple: false,
  });

  return (
    <div className={`dropzone ${disabled ? '-disabled' : ''} ${className}`} {...getRootProps()}>
      <h3 className="dropzone__label -bold">{label}</h3>

      <i className="dropzone__icon">
        <UploadIcon />
      </i>

      <h3 className="dropzone__label -small">{i18n.t('upload.dragAndDrop')}</h3>
      <h3 className="dropzone__label -bold">{i18n.t('upload.or')}</h3>
      <h3 className="dropzone__label -last">{i18n.t('upload.browseFile')}</h3>
      <input {...getInputProps()} {...inputProps} disabled={disabled} />
    </div>
  );
};

FileUpload.propTypes = {
  children: Children,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

FileUpload.defaultProps = {
  children: undefined,
  className: '',
  disabled: false,
  onChange: () => undefined,
};

export default React.memo(FileUpload);
