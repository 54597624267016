import enTranslation from '../__i18n__/en';
import frTranslation from '../__i18n__/fr';

export default {
  fallbackLng: 'fr',
  lng: 'fr',
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
  },
};
