import React, { useCallback, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowBackIcon } from '../../assets/icons/arrow-back.svg';
import { ReactComponent as ArrowForwardIcon } from '../../assets/icons/arrow-forward.svg';

const MonthNavigator = ({ onChange, isDisabled, disableMessage }) => {
  const [date, setDate] = useState(moment().startOf('month'));

  const handlePreviousClick = useCallback(() => {
    const newDate = date.clone().subtract(1, 'months');
    setDate(newDate);
    onChange(newDate);
  }, [date]);

  const handleNextClick = useCallback(() => {
    const newDate = date.clone().add(1, 'months');
    setDate(newDate);
    onChange(newDate);
  }, [date]);

  return (
    <div className={`month-navigator ${isDisabled ? '-disabled' : ''}`}>
      <button type="button" className="month-navigator__action" onClick={handlePreviousClick} disabled={isDisabled}>
        <ArrowBackIcon className="icon" />
      </button>
      <div className="month-navigator__period">{isDisabled && disableMessage ? disableMessage : date.format('MMMM YYYY')}</div>
      <button type="button" className="month-navigator__action" onClick={handleNextClick} disabled={isDisabled}>
        <ArrowForwardIcon className="icon" />
      </button>
    </div>
  );
};

MonthNavigator.propTypes = {
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  disableMessage: PropTypes.string,
};

MonthNavigator.defaultProps = {
  isDisabled: false,
  disableMessage: '',
};

export default React.memo(MonthNavigator);
