import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../types';

const Popdown = ({ children, className, onClickOutside, isOpen }) => {
  const contentRef = useRef(null);
  const handleMenuClick = useCallback(e => e.stopPropagation());

  const handleClickOutside = useCallback(
    e => {
      if (contentRef.current && !contentRef.current.contains(e.target) && isOpen) {
        onClickOutside(e);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, [handleClickOutside]);

  return (
    <menu ref={contentRef} onClick={handleMenuClick} className={`popdown ${className} ${isOpen ? '-open' : ''}`}>
      {children}
    </menu>
  );
};

Popdown.propTypes = {
  children: Children.isRequired,
  isOpen: PropTypes.bool,
  onClickOutside: PropTypes.func,
  className: PropTypes.string,
};

Popdown.defaultProps = {
  isOpen: false,
  className: '',
  onClickOutside: () => undefined,
};

export default React.memo(Popdown);
