import React from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';

const ListHeaderCol = ({ className, children, onSort, sortKey, sortParams }) => {
  const sortClass = sortKey && sortKey === sortParams.key ? `-sort-${sortParams.order}` : '';

  return (
    <div
      className={`list__col -title ${sortKey ? '-sortable' : ''} ${sortClass} ${className}`}
      onClick={() => onSort(sortKey)}
      role="button"
      tabIndex="0">
      {children}
      {sortKey && <SortIcon />}
    </div>
  );
};

ListHeaderCol.propTypes = {
  className: PropTypes.string,
  children: Children,
  onSort: PropTypes.func,
  sortKey: PropTypes.string,
  sortParams: PropTypes.shape({ key: PropTypes.string, order: PropTypes.string }),
};

ListHeaderCol.defaultProps = {
  className: '',
  children: undefined,
  onSort: () => {},
  sortKey: undefined,
  sortParams: {},
};

export default React.memo(ListHeaderCol);
