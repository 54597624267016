import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import i18n from 'i18next';
import moment from 'moment';
import firebase from 'firebase/app';

import { Router } from '../types';
import { useAgent } from '../hooks';
import { zohoAgent, invoiceAgent, pdfTablesAgent } from '../agents';
import AppContext from '../contextes/AppContext';
import { getInvoiceSummaryItems, validateInvoice } from '../helpers/invoiceHelpers';
import { getXLSXData } from '../helpers/reportHelpers';
import ReportAnalyser from '../helpers/analyser/ReportAnalyser';
import { Loader } from '../components/common';
import TransportList from '../components/carrierInvoice/TransportList';
import TransportListTabs from '../components/carrierInvoice/TransportListTabs';
import CarrierInvoiceHeader from '../components/carrierInvoice/CarrierInvoiceHeader';
import InvoiceSummaryPanel from '../components/carrierInvoice/InvoiceSummaryPanel';
import DeleteInvoiceModal from '../components/carrierInvoice/DeleteInvoiceModal';

const CarrierInvoicePage = ({ match, history }) => {
  const appContext = useContext(AppContext);

  const [getCarriers, , isFetchingCarriers] = useAgent(zohoAgent.getCarriers);
  const [getZohoInvoiceItems, zohoInvoiceItems, isFetchingZohoInvoiceItems] = useAgent(zohoAgent.getInvoiceItems);
  const [getZohoInvoice, zohoInvoice, isFetchingZohoInvoice] = useAgent(zohoAgent.getInvoice);
  const [createInvoice, , isCreatingInvoice] = useAgent(zohoAgent.createInvoice);
  const [updateZohoInvoice, , isUpdatingZohoInvoice] = useAgent(zohoAgent.updateInvoice);
  const [deleteZohoInvoice, , isDeletingZohoInvoice] = useAgent(zohoAgent.deleteInvoice);
  const [getInvoice, , isFetchingInvoice] = useAgent(invoiceAgent.get);
  const [getPDFData, , isFetchingPDF] = useAgent(pdfTablesAgent.getPDFData);
  const [updateInvoice, , isUpdatingInvoice] = useAgent(invoiceAgent.update);
  const [updateInvoiceDiscreetly] = useAgent(invoiceAgent.update);
  const [deleteInvoice] = useAgent(invoiceAgent.remove);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [carrier, setCarrier] = useState({});
  const [activeTab, setActiveTab] = useState('');
  const [invoice, setInvoice] = useState({});
  const [clientCommissionStates, setClientCommissionStates] = useState({});
  const [clientCurrencyStates, setClientCurrencyStates] = useState({});
  const [exchangeRate, setExchangeRate] = useState(Number(localStorage.getItem('exchangeRate')) || 0);
  const [hasTransportInUSD, setHasTransportInUSD] = useState(false);

  const clientTransports = useMemo(() => _.filter(invoice.transports, t => t.clientKey === activeTab), [activeTab, invoice.transports]);
  const invoiceItems = useMemo(() => getInvoiceSummaryItems(invoice, carrier, exchangeRate), [invoice, carrier, exchangeRate]);
  const invoiceErrors = useMemo(() => validateInvoice(invoice.transports, invoice.clients, invoice.groupeInvoiceLineBy), [
    invoice.transports,
    invoice.clients,
    invoice.groupeInvoiceLineBy,
  ]);

  const updateAndSaveInvoice = useCallback(updatedInvoice => {
    setInvoice(updatedInvoice);
    updateInvoiceDiscreetly(match.params.id, updatedInvoice);
  }, []);

  const handleSendToZoho = useCallback(() => {
    const invoiceToCreate = {
      customer_id: invoice.carrierId,
      line_items: invoiceItems,
      date: moment(invoice.date).format('YYYY-MM-DD'),
      payment_terms: 30,
      payment_terms_label: '30 Net',
    };

    createInvoice(invoiceToCreate).then(i => {
      updateInvoice(match.params.id, { zohoId: i.invoiceId, exchangeRate }).then(() => history.push(`/invoices/success/${i.invoiceId}`));
    });
  }, [invoice, invoiceItems]);

  const handleUpdateZohoInvoice = useCallback(() => {
    const invoiceToUpdate = {
      customer_id: invoice.carrierId,
      line_items: invoiceItems,
      date: moment(invoice.date).format('YYYY-MM-DD'),
      payment_terms: 30,
      payment_terms_label: '30 Net',
      reason: i18n.t('carrierInvoice.updateZohoReason'),
    };

    updateZohoInvoice(invoice.zohoId, invoiceToUpdate).then(() => updateInvoice(match.params.id, { exchangeRate }));
  }, [invoice, invoiceItems]);

  const handleExchangeRateChange = useCallback(rate => {
    setExchangeRate(rate);
    localStorage.setItem('exchangeRate', rate);
  }, []);

  const handleExchangeRateToggle = useCallback(
    isOn => {
      if (!isOn) {
        const updatedTransports = invoice.transports.map(t => ({ ...t, currency: 'CAD' }));
        updateAndSaveInvoice({ ...invoice, transports: updatedTransports });

        const currencyStates = {};

        _.each(invoice.clients, c => {
          currencyStates[c.key] = false;
        });

        setClientCurrencyStates(currencyStates);
      }
    },
    [invoice]
  );

  const handleValueChange = useCallback(
    (transportId, fieldName, newValue) => {
      const transportIndex = invoice.transports.findIndex(t => t.id === transportId);
      const updatedTransport = { ...invoice.transports[transportIndex], [fieldName]: newValue };
      const updatedTransports = _.values({ ...invoice.transports, [transportIndex]: updatedTransport });

      updateAndSaveInvoice({ ...invoice, transports: updatedTransports });
    },
    [invoice]
  );

  const handleItemChange = useCallback(
    (itemId, clientKey) => {
      const updatedClient = { ...invoice.clients[clientKey], itemId };
      const updatedClients = { ...invoice.clients, [clientKey]: updatedClient };

      updateAndSaveInvoice({ ...invoice, clients: updatedClients });
    },
    [invoice]
  );

  const handleGlobalCommissionChange = useCallback(
    commission => {
      const updatedTransports = invoice.transports.map(t => {
        return t.clientKey === activeTab ? { ...t, commissionType: commission.key } : t;
      });

      updateAndSaveInvoice({ ...invoice, transports: updatedTransports });
      setClientCommissionStates({ ...clientCommissionStates, [activeTab]: commission.key === 'LTL' });
    },
    [invoice, activeTab, clientCommissionStates]
  );

  const handleGlobalCurrencyChange = useCallback(
    currency => {
      const updatedTransports = invoice.transports.map(t => {
        return t.clientKey === activeTab ? { ...t, currency: currency.key } : t;
      });

      updateAndSaveInvoice({ ...invoice, transports: updatedTransports });
      setClientCurrencyStates({ ...clientCurrencyStates, [activeTab]: currency.key === 'USD' });
    },
    [invoice, activeTab, clientCurrencyStates]
  );

  const handleInvoiceDateChange = useCallback(newDate => updateAndSaveInvoice({ ...invoice, date: newDate }), [invoice]);

  const handleDeleteInvoiceClick = useCallback(() => setIsModalVisible(true), []);

  const handleModalClose = useCallback(() => setIsModalVisible(false), []);

  const handleModalApply = useCallback(async () => {
    setIsModalVisible(false);

    if (invoice.zohoId && zohoInvoice) {
      await deleteZohoInvoice(invoice.zohoId);
    }

    await deleteInvoice(match.params.id);

    history.push('/');
  }, [match, invoice, zohoInvoice]);

  const saveFile = useCallback(async (invoiceId, file) => {
    const storageRef = firebase.storage().ref();
    const today = new Date();
    const ref = storageRef.child(`${invoiceId}/${today.toISOString()}-${file.name}`);
    await ref.put(file);
  }, []);

  const handleReuploadFile = useCallback(
    async e => {
      let data;
      const file = e.target.files[0];
      const filePath = e.target.files[0].name.split('.');
      const extension = filePath[filePath.length - 1];

      if (extension === 'pdf') {
        data = await getPDFData(e.target.files[0]);
      } else if (extension === 'xlsx' || extension === 'xls') {
        data = await getXLSXData(e.target.files[0]);
      }

      const analysedInvoice = data ? ReportAnalyser.analyse(data, invoice.carrierId) : null;

      if (analysedInvoice) {
        const invoiceToUpdate = { ...analysedInvoice, zohoId: invoice.zohoId, exchangeRate: invoice.exchangeRate };
        await updateInvoice(match.params.id, invoiceToUpdate);
        setInvoice(invoiceToUpdate);
        saveFile(match.params.id, file);
      }
    },
    [invoice]
  );

  useEffect(() => {
    getInvoice(match.params.id).then(i => {
      setInvoice(i);
      setActiveTab(_.values(i.clients)[0] ? _.values(i.clients)[0].key : '');
      if (i.zohoId && i.exchangeRate) setExchangeRate(i.exchangeRate);

      getZohoInvoiceItems();
      if (i.zohoId)
        getZohoInvoice(i.zohoId).then(zohoInv => {
          if (!zohoInv) updateAndSaveInvoice({ ...i, zohoId: '' });
        });

      getCarriers().then(carriers => {
        const invoiceCarrier = carriers.find(c => c.contactId === i.carrierId);

        if (invoiceCarrier) setCarrier(invoiceCarrier);
      });

      const commissionStates = {};
      const currencyStates = {};

      _.each(i.clients, c => {
        clientCommissionStates[c.key] = false;
        currencyStates[c.key] = false;
      });

      setClientCommissionStates(commissionStates);
      setClientCurrencyStates(currencyStates);
      appContext.dispatch({ type: 'SET_NAVBAR_TITLE', payload: i18n.t('navbar.invoice') });

      const transportWithUSD = i.transports.find(t => t.currency === 'USD');
      if (transportWithUSD) setHasTransportInUSD(true);
    });
  }, []);

  const isLoading =
    isFetchingInvoice ||
    isFetchingCarriers ||
    isCreatingInvoice ||
    isUpdatingInvoice ||
    isUpdatingZohoInvoice ||
    isDeletingZohoInvoice ||
    isFetchingZohoInvoiceItems ||
    isFetchingZohoInvoice ||
    isFetchingPDF;

  return (
    <>
      {isLoading && <Loader />}

      <CarrierInvoiceHeader
        title={carrier.contactName}
        onSendToZoho={handleSendToZoho}
        onInvoiceDateChange={handleInvoiceDateChange}
        invoice={invoice}
        onDeleteClick={handleDeleteInvoiceClick}
        onUpdateZohoInvoice={handleUpdateZohoInvoice}
        onReuploadFile={handleReuploadFile}
        errors={invoiceErrors}
      />

      <InvoiceSummaryPanel items={invoiceItems} zohoInvoiceItems={zohoInvoiceItems} errors={invoiceErrors} onItemChange={handleItemChange} />

      <div className="panel__wrapper">
        <TransportListTabs tabs={_.values(invoice.clients)} activeTab={activeTab} onActiveTabChange={setActiveTab} />

        <TransportList
          tlCommission={Number(carrier.cfTl)}
          ltlCommission={Number(carrier.cfLtl)}
          transports={clientTransports}
          exchangeRate={exchangeRate}
          isCommissionSwitchActive={clientCommissionStates[activeTab]}
          isCurrencySwitchActive={clientCurrencyStates[activeTab]}
          hasTransportInUSD={hasTransportInUSD}
          hasCustomRates={invoice.hasCustomRates}
          onValueChange={handleValueChange}
          onGlobalCommissionChange={handleGlobalCommissionChange}
          onGlobalCurrencyChange={handleGlobalCurrencyChange}
          onExchangeRateChange={handleExchangeRateChange}
          onExchangeRateToggle={handleExchangeRateToggle}
        />

        <DeleteInvoiceModal isModalVisible={isModalVisible} onApply={handleModalApply} onClose={handleModalClose} />
      </div>
    </>
  );
};

CarrierInvoicePage.propTypes = {
  match: Router.Match.isRequired,
  history: Router.History.isRequired,
};

export default CarrierInvoicePage;
