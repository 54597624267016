export const formatAmount = amount => {
  let result = '';

  if (amount) {
    result = amount
      .toString()
      .replace(' ', '')
      .replace('$', '')
      .trim();
  }

  if (result[result.length - 3] === ',') {
    result = `${result.substring(0, result.length - 3)}.${result.substring(result.length - 2)}`;
  }

  return !Number.isNaN(Number(result.replace(',', ''))) ? Number(result.replace(',', '')) : result;
};

export default { formatAmount };
