import axios from 'axios';
import csvtojson from 'csvtojson';
import _ from 'lodash';

import testCSV from '../testData/csv/TRANSPORT_JEAN_FRANCOIS/TransportJeanFrancois002.csv.js';

const baseUrl = 'https://pdftables.com/api';

const getPDFData = async pdf => {
  // INFO: Test using testData since we don't have unlimited credits
  let data;

  if (process.env.REACT_APP_MOCK_PDF_TABLES === 'true' || process.env.REACT_APP_MOCK_PDF_TABLES === true) {
    data = testCSV;
  } else {
    const formData = new window.FormData();
    formData.append('formfile', pdf);
    const options = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await axios.post(`${baseUrl}?key=${process.env.REACT_APP_PDF_TABLES_API_KEY}&format=csv`, formData, options);
    // eslint-disable-next-line prefer-destructuring
    data = response.data;
  }

  const result = await csvtojson({ noheader: true, trim: true, output: 'csv' }).fromString(data);

  return result.map(row => _.compact(row));
};

export default { getPDFData };
