import firebase from 'firebase/app';
import 'firebase/storage';

import moment from 'moment';

const collectionName = 'invoices';

const create = async invoice => {
  const db = firebase.firestore();
  const docRef = await db.collection(collectionName).add(invoice);

  return docRef.id;
};

const get = async id => {
  const db = firebase.firestore();
  const report = await db
    .collection(collectionName)
    .doc(id)
    .get();

  if (!report.exists) throw { code: '404', message: `Report ${id} doesn't exist` };

  const data = report.data();

  return { ...data, date: data.date.toDate() };
};

const getReport = async (startDate, endDate, carrierId) => {
  const reports = [];
  const db = firebase.firestore();
  let invoicesTable = db.collection(collectionName);

  if (startDate && endDate) invoicesTable = invoicesTable.where('date', '>=', startDate).where('date', '<=', endDate);
  if (carrierId) invoicesTable = invoicesTable.where('carrierId', '==', carrierId);

  const filteredReports = await invoicesTable.get();

  filteredReports.forEach(report => {
    const data = report.data();
    const reportWithId = { ...data, invoiceId: report.id, date: moment(data.date.toDate()).format('YYYY-MM-DD') };
    reports.push(reportWithId);
  });

  return reports;
};

const remove = async id => {
  const db = firebase.firestore();

  await db
    .collection(collectionName)
    .doc(id)
    .delete();
};

const update = async (id, delta) => {
  const db = firebase.firestore();

  await db
    .collection(collectionName)
    .doc(id)
    .update(delta);
};

export default { create, get, getReport, remove, update };
