import React, { useCallback, useContext } from 'react';
import i18n from 'i18next';
import firebase from 'firebase/app';

import AppContext from '../contextes/AppContext';
import NavbarContent from '../components/layout/NavbarContent';

const Navbar = () => {
  const appContext = useContext(AppContext);

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem('zohoAccessToken');
    window.localStorage.removeItem('zohoRefreshToken');
    window.localStorage.removeItem('zohoExpiresIn');
    firebase.auth().signOut();
  });

  const handleChangeLanguage = useCallback(() => {
    localStorage.setItem('language', i18n.language === 'en' ? 'fr' : 'en');
    window.location.reload();
  }, []);

  return (
    <NavbarContent
      onLogout={handleLogout}
      onChangeLanguage={handleChangeLanguage}
      user={appContext.state.user}
      title={appContext.state.navbarTitle}
    />
  );
};

export default Navbar;
