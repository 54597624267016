import { formatAmount } from '../analyserHelpers';

const carrierId = process.env.REACT_APP_JOZ_ID;

const isMatching = rows => {
  return rows.find(r => {
    const fullText = r
      .join('')
      .split(' ')
      .join('')
      .toUpperCase();

    return fullText.includes('TRANSPORTJOZ');
  });
};

const cleanDate = date => {
  const dateWithCorrectFormat = `${date.slice(0, 4)}-${date.slice(5, 7)}-${date.slice(8)}`;

  return dateWithCorrectFormat
    .replace(/o/g, '0')
    .replace(/O/g, '0')
    .replace(/r/g, '1')
    .replace(/t/g, '1')
    .replace(/L/g, '1')
    .replace(/s/g, '5');
};

const analyseData = rows => {
  const transports = [];
  let lastClient = '';

  rows.forEach(row => {
    if (row.length > 7) {
      if (row.length === 8) {
        const currency = row[4].toUpperCase() === 'CAN' ? 'CAD' : 'USD';
        const amount = formatAmount(row[5]);
        const commissionType = row[6].includes('3') ? 'TL' : 'LTL';
        const date = cleanDate(row[3]);

        transports.push({ date, from: row[1], to: row[2], amount, client: lastClient, currency, commissionType });
      } else if (row.length === 9) {
        const currency = row[5].toUpperCase() === 'CAN' ? 'CAD' : 'USD';
        const amount = formatAmount(row[6]);
        const commissionType = row[7].includes('3') ? 'TL' : 'LTL';
        const date = cleanDate(row[4]);
        const client = row[0];
        lastClient = client;

        transports.push({ date, from: row[2], to: row[3], amount, client, currency, commissionType });
      } else if (row.length === 10) {
        const currency = row[6].toUpperCase() === 'CAN' ? 'CAD' : 'USD';
        const amount = formatAmount(row[7]);
        const commissionType = row[8].includes('3') ? 'TL' : 'LTL';
        const client = `${row[0]} ${row[1]}`;
        lastClient = client;
        const date = cleanDate(row[5]);

        transports.push({ date, from: row[3], to: row[4], amount, client, currency, commissionType });
      }
    }
  });

  return transports;
};

export default { carrierId, isMatching, analyseData };
