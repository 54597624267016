import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import { User } from '../../types';
import { ReactComponent as CaretDownIcon } from '../../assets/icons/caret-down.svg';
import { Popdown } from '../common';

const NavbarContent = ({ user, onChangeLanguage, onLogout, title }) => {
  const [isUserMenuVisible, setIsMenuVisible] = useState(false);

  const toggleUserMenu = useCallback(
    e => {
      e.stopPropagation();
      setIsMenuVisible(!isUserMenuVisible);
    },
    [isUserMenuVisible]
  );

  return (
    <header className="navbar">
      <div className="navbar__container">
        <div className="navbar__title">
          <Link to="/">{title || ''}</Link>
        </div>

        <Link to="/reports">{i18n.t('navbar.invoiceHistory')}</Link>

        <div className="actions">
          <button type="button" className={`button -dropdown${isUserMenuVisible ? ' -open' : ''}`} onClick={toggleUserMenu}>
            {user.email || i18n.t('navbar.menu')}
            <CaretDownIcon />
          </button>

          <Popdown isOpen={isUserMenuVisible} onClickOutside={toggleUserMenu} className="actions__list">
            <li className="actions__option" onClick={onChangeLanguage}>
              {i18n.language === 'en' ? 'Français' : 'English'}
            </li>

            {!user && (
              <li className="actions__option">
                <Link to="/login">{i18n.t('navbar.login')}</Link>
              </li>
            )}

            {user && (
              <li className="actions__option" onClick={onLogout}>
                {i18n.t('navbar.logout')}
              </li>
            )}
          </Popdown>
        </div>
      </div>
    </header>
  );
};

NavbarContent.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user: User,
};

NavbarContent.defaultProps = {
  user: {},
};

export default React.memo(NavbarContent);
