import React from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';

const ListRow = ({ className, children, onClick }) => (
  <li className={`list__row ${onClick ? '-clickable' : ''} ${className}`} onClick={onClick}>
    {children}
  </li>
);

ListRow.propTypes = {
  className: PropTypes.string,
  children: Children.isRequired,
  onClick: PropTypes.func,
};

ListRow.defaultProps = {
  className: '',
  onClick: undefined,
};

export default React.memo(ListRow);
