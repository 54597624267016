import _ from 'lodash';
import moment from 'moment';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';

import { getLastDayOfMonth, getUTCDate, getMostOccurringMonth } from '../momentHelpers';

import GenericExcelAnalyser from './GenericExcelAnalyser';
import GenericPDFAnalyser from './GenericPDFAnalyser';
import TransportTFI4Analyser from './TransportTFI4Analyser';
import JAGIncAnalyser from './JAGIncAnalyser';
import TransportJOZAnalyser from './TransportJOZAnalyser';
import PelchatTransportAnalyser from './PelchatTransportAnalyser';
import CRSExpressAnalyser from './CRSExpressAnalyser';
import MalbertTransportAnalyser from './MalbertTransportAnalyser';
import CHExpressAnalyser from './CHExpressAnalyser';
import JGRSBoudreaultAnalyser from './JGRSBoudreaultAnalyser';
import TransportJaguarAnalyser from './TransportJaguarAnalyser';
import TransportBourassaAnalyser from './TransportBourassaAnalyser';
import GilmyrAnalyser from './GilmyrAnalyser';
import GroupeAvanatgeLogistiquesAnalyser from './GroupeAvanatgeLogistiquesAnalyser';
import ChantierChibougameauAnalyser from './ChantierChibougameauAnalyser';
import MachitechAnalyser from './MachitechAnalyser';
import TransportJFAnalyser from './TransportJFAnalyser';
import BessetteBoudreauAnalyser from './BessetteBoudreaulAnalyser';

const TransportGadouryAnalyser = {
  carrierId: process.env.REACT_APP_TRANSPORT_GADOURY_ID,
  isMatching: GenericExcelAnalyser.isMatching('GADOURY'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const TransportStPamphileAnalyser = {
  carrierId: process.env.REACT_APP_TRANSPORT_ST_PAMPHILE_ID,
  isMatching: GenericExcelAnalyser.isMatching('ST-PAMPHILE'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const CoutureExpresswayAnalyser = {
  carrierId: process.env.REACT_APP_COUTURE_EXPRESSWAY_ID,
  isMatching: GenericExcelAnalyser.isMatching('COUTURE EXPRESSWAY'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const VolumeTankAnalyser = {
  carrierId: process.env.REACT_APP_VOLUME_TANK_ID,
  isMatching: GenericExcelAnalyser.isMatching('VOLUME TANK'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const GlobcoAnalyser = {
  carrierId: process.env.REACT_APP_GLOBCO_ID,
  isMatching: GenericPDFAnalyser.isMatching('GLOBCO INTERNATIONAL INC.'),
  analyseData: GenericPDFAnalyser.analyseData,
  metaData: { groupeInvoiceLineBy: 'poNumber' },
};

const JMSarazinAnalyser = {
  carrierId: process.env.REACT_APP_JM_SARAZIN_ID,
  isMatching: GenericExcelAnalyser.isMatching('SARAZIN'),
  analyseData: GenericExcelAnalyser.analyseData,
  metaData: { groupeInvoiceLineBy: 'poNumber' },
};

const LesEntreprisesEricDupontAnalyser = {
  carrierId: process.env.REACT_APP_ERIC_DUPONT_ID,
  isMatching: GenericExcelAnalyser.isMatching('ERIC DUPONT'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const MexuscanAnalyser = {
  carrierId: process.env.REACT_APP_MEXUSCAN_ID,
  isMatching: GenericExcelAnalyser.isMatching('MEXUSCAN'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const JeanLucTanguayAnalyser = {
  carrierId: process.env.REACT_APP_JEAN_LUC_TANGUAY_ID,
  isMatching: GenericExcelAnalyser.isMatching('JEAN-LUC TANGUAY'),
  analyseData: GenericExcelAnalyser.analyseData,
};

const analysers = [
  BessetteBoudreauAnalyser,
  TransportTFI4Analyser,
  JAGIncAnalyser,
  TransportJOZAnalyser,
  LesEntreprisesEricDupontAnalyser,
  PelchatTransportAnalyser,
  GlobcoAnalyser,
  CRSExpressAnalyser,
  MalbertTransportAnalyser,
  TransportGadouryAnalyser,
  TransportStPamphileAnalyser,
  CoutureExpresswayAnalyser,
  VolumeTankAnalyser,
  CHExpressAnalyser,
  JGRSBoudreaultAnalyser,
  TransportJaguarAnalyser,
  TransportBourassaAnalyser,
  JMSarazinAnalyser,
  GilmyrAnalyser,
  GroupeAvanatgeLogistiquesAnalyser,
  MexuscanAnalyser,
  ChantierChibougameauAnalyser,
  MachitechAnalyser,
  TransportJFAnalyser,
  JeanLucTanguayAnalyser,
];

const analyse = (data, carrierId, fileExtension) => {
  let transports;
  let analyser;

  try {
    analyser = analysers.find(a => {
      return carrierId ? a.carrierId === carrierId : a.isMatching(_.isArray(data) ? data : Object.values(data));
    });

    if (!analyser) {
      if (!carrierId) return null;

      analyser = { carrierId, analyseData: fileExtension === 'pdf' ? GenericPDFAnalyser.analyseData : GenericExcelAnalyser.analyseData };
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  try {
    transports = analyser.analyseData(data);
  } catch (error) {
    toast.error(i18n.t('unableToAnalyseReport'), { autoClose: 5000 });
    const db = firebase.firestore();
    db.collection('errors')
      .add({
        message: `Unable to analyse file for carrier: ${analyser ? analyser.carrierId : 'NONE'}`,
        data: JSON.stringify(data),
        datetime: new Date(
          moment()
            .utc()
            .format()
        ),
      })
      .catch(_.noop);

    // eslint-disable-next-line no-console
    console.error(error);

    return null;
  }

  const clients = {};

  transports = transports.map(t => {
    if (!t.client) return t;

    let client = _.find(clients, c => c.name === t.client);

    if (!client) {
      client = { name: t.client, key: _.uniqueId('CLIENT_'), itemId: null };
      clients[client.key] = client;
    }

    return { ..._.omit(t, ['client']), id: _.uniqueId('TRANSPORT_'), clientKey: client.key };
  });

  const transportWithDate = transports.find(t => t.date);

  if (!transportWithDate) {
    // We guess the date based on the fact that they will analyse last month reports
    const previousMonth = moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
      .toString();

    transports = transports.map(t => ({ ...t, date: getLastDayOfMonth(previousMonth) }));
  }

  const mostOccurringMonth = getMostOccurringMonth(transports.map(t => t.date));
  const transportWithMostOccurringMonth = transports.find(t => t.date.substring(5, 7) === mostOccurringMonth);
  const invoiceDate = transportWithMostOccurringMonth ? moment(transportWithMostOccurringMonth.date) : moment('');
  const fallbackDate = moment()
    .add(-1, 'month')
    .endOf('month');

  return {
    carrierId: analyser.carrierId,
    transports,
    clients,
    date: transportWithMostOccurringMonth
      ? getUTCDate(getLastDayOfMonth(invoiceDate.isValid() ? transportWithMostOccurringMonth.date : moment().subtract('1', 'month')))
      : getUTCDate(fallbackDate),
    zohoId: '',
    exchangeRate: null,
    ...(analyser.metaData || {}),
  };
};

export default { analyse };
