import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import i18n from 'i18next';
import _ from 'lodash';

import { Loader } from '../components/common';
import AppContext from '../contextes/AppContext';
import { useAgent } from '../hooks';
import { invoiceAgent, zohoAgent } from '../agents';
import { getUTCDate } from '../helpers/momentHelpers';
import { generateReports } from '../helpers/invoiceHelpers';
import ReportListHeader from '../components/report/ReportListHeader';
import CarrierReportList from '../components/report/CarrierReportList';

const ReportListPage = () => {
  const appContext = useContext(AppContext);
  const [dates, setDates] = useState({ start: moment().startOf('month'), end: moment().endOf('month') });
  const [carrierId, setCarrierId] = useState('');
  const [getReport, reports = [], isGettingReport] = useAgent(invoiceAgent.getReport);
  const [getCarriers, carriers = [], isGettingCarriers] = useAgent(zohoAgent.getCarriers);
  const carriersReports = useMemo(() => _.orderBy(generateReports(reports, carriers), 'date', 'desc'), [reports, carriers]);

  const handleDateChange = useCallback(newDate => setDates({ start: newDate, end: newDate.clone().endOf('month') }), []);

  useEffect(() => {
    if (carrierId) getReport(null, null, carrierId);
    else getReport(getUTCDate(dates.start), getUTCDate(dates.end));
  }, [dates, carrierId]);

  useEffect(() => {
    getCarriers();
    appContext.dispatch({ type: 'SET_NAVBAR_TITLE', payload: i18n.t('navbar.invoiceHistory') });
  }, []);

  return (
    <>
      {(isGettingReport || isGettingCarriers) && <Loader />}

      <ReportListHeader
        onDateChange={handleDateChange}
        onCarrierSelect={setCarrierId}
        carriers={carriers}
        isMonthNavigatorDisabled={!!carrierId}
        disableMonthNavigatorMessage={i18n.t('report.allMonths')}
      />

      <CarrierReportList carriersReport={carriersReports} isLoading={isGettingReport || reports.length !== carriersReports.length} />
    </>
  );
};

export default ReportListPage;
