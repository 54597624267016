import _ from 'lodash';
import { isNaN, isZero, isNegative } from './fieldHelpers';

export const getInvoiceSummaryItems = (invoice, carrier, exchangeRate) => {
  const items = [];
  const groupBy = invoice.groupeInvoiceLineBy || 'clientKey';
  const transportsByClient = _.groupBy(invoice.transports, 'clientKey');

  _.each(transportsByClient, (transportsForClient, key) => {
    const transportByRate = _.groupBy(transportsForClient, invoice.hasCustomRates ? 'rate' : 'commissionType');
    const client = Object.values(invoice.clients).find(c => c.key === key || (groupBy === 'poNumber' && c.key === transportsForClient[0].clientKey));
    const poNumbersDesc = invoice.groupeInvoiceLineBy === 'poNumber' && transportsForClient.map(t => t.poNumber);
    const description = groupBy === 'clientKey' ? invoice.clients[key].name : poNumbersDesc.join(' - ');

    _.each(transportByRate, (transports, transportRate) => {
      let quantity = 0;
      let rate;

      if (invoice.hasCustomRates) {
        rate = Number(transportRate);
      } else {
        rate = !_.isEmpty(carrier) ? Number(transportRate === 'TL' ? carrier.cfTl : carrier.cfLtl) : 0;
      }

      _.each(transports, t => {
        if (!Number.isNaN(Number(t.amount))) {
          const transportTotal = t.currency === 'USD' ? t.amount * exchangeRate : t.amount;
          const roundedTotal = Math.round((transportTotal + Number.EPSILON) * 100) / 100;

          quantity = parseFloat((quantity + roundedTotal).toFixed(2));
        }
      });

      items.push({
        clientKey: groupBy === 'poNumber' ? transportsForClient[0].clientKey : key,
        item_id: groupBy === 'poNumber' ? process.env.REACT_APP_COMMANDES_ITEM_ZOHO_ID : client.itemId,
        description,
        rate,
        quantity,
      });
    });
  });

  return items;
};

export const generateReports = (data, carriers) => {
  if (!carriers || carriers.length === 0) return [];

  const reports = data.map(invoice => {
    let amount = 0;
    let commissionAmount = 0;
    const carrier = carriers.find(c => c.contactId === invoice.carrierId);

    invoice.transports.forEach(transport => {
      let commissionRatio = 0;
      if (invoice.hasCustomRates) {
        commissionRatio = transport.rate;
      } else {
        commissionRatio = transport.commissionType === 'TL' ? Number(carrier.cfTl) : Number(carrier.cfLtl);
      }

      const transportTotal = transport.currency === 'USD' ? transport.amount * invoice.exchangeRate : transport.amount;
      const roundedTotal = Math.round((transportTotal + Number.EPSILON) * 100) / 100;
      amount += roundedTotal;
      commissionAmount += roundedTotal * commissionRatio;
    });

    return {
      carrierId: invoice.carrierId,
      carrierName: carrier.contactName,
      amount,
      commissionAmount,
      date: invoice.date,
      invoiceId: invoice.invoiceId,
      zohoId: invoice.zohoId,
    };
  });

  return reports;
};

export const getCommissionsTotal = items => {
  let total = 0;

  items.forEach(item => {
    if (!Number.isNaN(item.quantity) && !Number.isNaN(item.rate)) {
      const commissionAmount = item.quantity * item.rate;
      total += Number(commissionAmount.toFixed(2));
    }
  });

  return total;
};

export const validateInvoice = (transports, clients, groupeInvoiceLineBy = undefined) => {
  const errors = [];

  if (transports && clients) {
    transports.forEach(t => {
      const isNaNError = isNaN(t.amount);
      const isZeroError = isZero(t.amount);
      const isNegativeError = isNegative(t.amount);

      if (isNaNError) errors.push({ client: clients[t.clientKey], amount: t.amount, error: 'isNaN' });
      else if (isZeroError) errors.push({ client: clients[t.clientKey], amount: t.amount, error: 'isZero' });
      else if (isNegativeError) errors.push({ client: clients[t.clientKey], amount: t.amount, error: 'isNegative' });
    });

    if (!groupeInvoiceLineBy) {
      Object.values(clients).forEach(c => {
        if (!c || (c && !c.itemId)) errors.push({ client: c, amount: '', error: 'isNotZohoItem' });
      });
    }
  }

  return errors;
};

export default { getInvoiceSummaryItems, generateReports, getCommissionsTotal, validateInvoice };
