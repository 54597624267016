import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'react-dates/initialize';
import 'moment/locale/fr';

import './styles/index.scss';
import App from './App';
import firebaseConfig from './config/firebaseConfig';
import i18nConfig from './config/i18nconfig';

firebase.initializeApp(firebaseConfig);

if (process.env.REACT_APP_USE_EMULATOR) {
  firebase.firestore().settings({
    host: 'localhost:8888',
    ssl: false,
  });
  firebase.functions().useEmulator('localhost', 5001);
}

const language = localStorage.getItem('language');

i18n
  .init({ ...i18nConfig, lng: language }, () => {
    moment.locale(language || i18nConfig.lng);
  })
  .then(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken().then(accessToken => {
          ReactDOM.render(<App user={{ ...user, accessToken }} />, document.getElementById('root'));
        });
      } else {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
      }
    });
  });
