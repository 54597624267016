import React from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';

const List = ({ children, className }) => <div className={`list ${className}`}>{children}</div>;

List.propTypes = { children: Children.isRequired, className: PropTypes.string };

List.defaultProps = {
  className: '',
};

export default React.memo(List);
