import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { Report } from '../../types';
import { List, ListCol, ListHeader, ListHeaderCol, ListRow, ListBody, ListFooter } from '../common/list';
import { moneyFormatter } from '../../helpers/fieldHelpers';

const zeroDollar = '0.00 $';

const CarrierReportList = ({ carriersReport, isLoading }) => {
  let commissionTotal = 0;

  return (
    <>
      <List>
        <ListHeader>
          <ListHeaderCol className="-x-4">{i18n.t('carrierInvoice.state')}</ListHeaderCol>
          <ListHeaderCol className="-x-6">{i18n.t('carrierInvoice.carrier')}</ListHeaderCol>
          <ListHeaderCol className="-x-3">{i18n.t('carrierInvoice.reportDate')}</ListHeaderCol>
          <ListHeaderCol className="-x-3 -align-right">{i18n.t('carrierInvoice.amount')}</ListHeaderCol>
          <ListHeaderCol className="-x-4 -align-right">{i18n.t('carrierInvoice.commission')}</ListHeaderCol>
        </ListHeader>

        {carriersReport.length > 0 && (
          <>
            <ListBody>
              {carriersReport.map(report => {
                commissionTotal += Number(report.commissionAmount);
                return (
                  <Link key={_.uniqueId('REPORT_')} to={`/invoices/${report.invoiceId}`}>
                    <ListRow>
                      <ListCol className="-x-4 -on-top">
                        {report.zohoId && <span>{i18n.t('carrierInvoice.sent')}</span>}
                        {!report.zohoId && <span className="text__warning">{i18n.t('carrierInvoice.pending')}</span>}
                      </ListCol>
                      <ListCol className="-x-6 -on-top">
                        <span className="-pad-right">{report.carrierName}</span>
                      </ListCol>
                      <ListCol className="-x-3 -on-top">{report.date || i18n.t('carrierInvoice.noDate')}</ListCol>
                      <ListCol className="-x-3 -align-right -on-top">{`${moneyFormatter(report.amount) || zeroDollar}`}</ListCol>
                      <ListCol className="-x-4 -align-right -on-top">{`${moneyFormatter(report.commissionAmount) || zeroDollar}`}</ListCol>
                    </ListRow>
                  </Link>
                );
              })}
            </ListBody>
            <ListFooter className="-end">
              <p className="summary__item">{`${i18n.t('carrierInvoice.total')}: ${moneyFormatter(commissionTotal) || zeroDollar}`}</p>
            </ListFooter>
          </>
        )}

        {carriersReport.length === 0 && !isLoading && (
          <ListBody>
            <p className="list__message">{i18n.t('carrierInvoice.noInvoiceFound')}</p>
          </ListBody>
        )}
      </List>
    </>
  );
};

CarrierReportList.propTypes = {
  carriersReport: PropTypes.arrayOf(Report),
  isLoading: PropTypes.bool,
};

CarrierReportList.defaultProps = {
  carriersReport: [],
  isLoading: false,
};

export default React.memo(CarrierReportList);
