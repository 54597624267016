export default {
  globalError: 'Une erreur est survenue, veuillez réessayer!',
  unableToAnalyseReport: "Impossible d'analyser le rapport. Il est possible que le format du rapport ait changé ou que le mauvais transporteur ait été choisi.",
  button: {
    apply: 'Appliquer',
    continue: 'Continuer',
    cancel: 'Annuler',
    delete: 'Supprimer'
  },
  currency: {
    oneUSD: '1 USD = ',
    CAD: 'CAD',
    USD: 'USD'
  },
  commission: {
    TL: 'TL',
    LTL: 'LTL'
  },
  country: {
    all: 'Tous',
    canada: 'Canada',
    usa: 'É.-U.A.'
  },
  selectPlaceholder: 'Sélectionner une valeur',
  notFound: {
    backToSafety: 'Retour à la page d\'accueil',
    pageNotFoundText: 'Oops, la page que vous tentez d\'accéder n\'éxiste pas.',
    pageNotFoundTitle: 'Page introuvable',
  },
  navbar: {
    login: 'Connexion',
    logout: 'Déconnexion',
    menu: 'Menu',
    invoiceHistory: 'Historique des factures',
    invoice: 'Facture'
  },
  upload: {
    browseFile: 'Cliquer pour sélectionner',
    chooseCarrierFirst: 'Choisir le transporteur d\'abord',
    dragAndDrop: 'Glisser et déposer',
    or: 'Ou',
    analyzeFile: 'Analyser un fichier',
    chooseCarrier: 'Veuillez choisir le transporteur du rapport',
    uploadFile: 'Télécharger un fichier',
  },
  report: {
    carrierWithoutInvoice: 'Transporteur(s) sans facture',
    allMonths: 'Tous les mois',
    line: 'Ligne'
  },
  carrierInvoice: {
    amountCAD: 'Montant CAD',
    anount: 'Montant',
    carrier: 'Transporteur',
    carriers: 'Transporteurs',
    client: 'Client',
    clients: 'Clients',
    country: 'Pays',
    date: 'Date',
    dateRange: 'Intervalle de date',
    deleteModalMessage: 'Voulez-vous vraiment supprimer cette facture?',
    description: 'Description',
    endDate: 'Date de fin',
    from: 'Source',
    to: 'Destination',
    amount: 'Montant',
    originalAmount: 'Montant Original',
    currency: 'Devise',
    commisionType: 'Type de Commission',
    commission: 'Commission',
    noDate: 'Aucune date disponible pour ce rapport',
    reportDate: 'Date du rapport',
    reports: 'Rapports',
    total: 'Total',
    totals: 'Totaux',
    send: 'Envoyer',
    startDate: 'Date de début',
    totalAmount: 'Montant total',
    totalCommission: 'Commission totale',
    useExchangeRate: 'Utiliser un taux de change',
    clientCurrency: 'Devise du client',
    clientCommission: 'Commission du client',
    sendToZoho: 'Envoyer vers Zoho',
    success: 'Succès!',
    invoiceSent: 'La facture a été envoyé à Zoho',
    analyseAnotherFile: 'Analyser un autre fichier',
    viewInvoice: 'Accéder à la facture',
    invoiceCreated: 'FACTURE CRÉÉE',
    invoicePreview: 'Aperçu de la facture',
    commissionRate: 'Taux de la commission',
    totalWithoutTaxes: 'Total (sans taxes)',
    state: 'État',
    sent: 'Envoyé',
    pending: 'En cours',
    noInvoiceFound: 'Aucune facture trouvée',
    quantity: 'Quantité',
    unitPrice: 'Prix unitaire',
    updateZohoInvoice: 'Mettre à jour Zoho',
    updateZohoReason: 'Mis à jour via application',
    reupload: 'Re-télécharger',
    commisionRate: 'Taux de la commission'
  }
};
