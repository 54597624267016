import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import TextField from './TextField';
import { fieldValidator } from '../../../helpers/fieldHelpers';

const EditableTextField = ({ className, editClassName, name, fieldValue, formatter, onBlur, onChange, validate, ...inputProps }) => {
  const [isInEdition, setIsInEdition] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleEditClick = useCallback(() => setIsInEdition(true), []);

  const handleBlur = useCallback(() => {
    // TODO: Find a better way to avoid value flickering
    setTimeout(() => {
      setIsInEdition(false);
      onBlur();
    });
  }, [onBlur]);

  const handleBlurChange = useCallback(
    value => {
      if (validate) setHasError(fieldValidator(validate, value));
      onChange(value);
    },
    [validate, onChange]
  );

  useEffect(() => {
    if (isInEdition) document.getElementById(name).focus();
  }, [isInEdition]);

  useEffect(() => {
    if (validate) setHasError(fieldValidator(validate, fieldValue));
  }, [fieldValue]);

  return (
    <div className="editable">
      {!isInEdition && (
        <button className={`editable__button ${editClassName} ${validate && hasError ? '-error' : ''}`} type="button" onClick={handleEditClick}>
          {formatter ? formatter(fieldValue) : fieldValue}
          <EditIcon className="icon -margin-left" />
        </button>
      )}

      {isInEdition && (
        <TextField
          name={name}
          className={`${className} ${validate && hasError ? '-error' : ''}`}
          fieldValue={fieldValue}
          formatter={formatter}
          onBlur={handleBlur}
          onBlurChange={handleBlurChange}
          {...inputProps}
          blurOnEnter
        />
      )}
    </div>
  );
};

EditableTextField.propTypes = {
  className: PropTypes.string,
  editClassName: PropTypes.string,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  formatter: PropTypes.func,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
};

EditableTextField.defaultProps = {
  className: '',
  editClassName: '',
  fieldValue: undefined,
  disabled: false,
  onBlur: () => undefined,
  formatter: undefined,
  onChange: () => undefined,
  validate: undefined,
};

export default React.memo(EditableTextField);
