import React from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';

const ListFooter = ({ children, className }) => <div className={`list__footer ${className}`}>{children}</div>;

ListFooter.propTypes = { children: Children.isRequired, className: PropTypes.string };

ListFooter.defaultProps = {
  className: '',
};

export default React.memo(ListFooter);
