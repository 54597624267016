import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import i18n from 'i18next';

import getSelectStyles from '../../../styles/blocks/selectStyles';
import { fieldValidator } from '../../../helpers/fieldHelpers';

const SelectField = ({ label, name, className, value, options, disabled, onChange, labelKey, valueKey, isClearable, validate, ...inputProps }) => {
  const [fieldValue, setFieldValue] = useState(value);
  const [hasError, setHasError] = useState(false);

  const handleChange = useCallback(
    selectedOption => {
      if (validate) setHasError(fieldValidator(validate, selectedOption));
      setFieldValue(selectedOption ? selectedOption[valueKey] : null);
      onChange(selectedOption ? selectedOption[valueKey] : null);
    },
    [onChange]
  );

  useEffect(() => {
    if (validate) setHasError(fieldValidator(validate, value));
    setFieldValue(value[valueKey]);
  }, [value]);

  return (
    <div className={`input ${className} ${hasError ? '-error' : ''}`}>
      {label && (
        <label className="input__label" htmlFor={name}>
          {label}
        </label>
      )}

      <div className="input__select">
        <Select
          className={`input__field -select${disabled ? ' -disabled' : ''}`}
          classNamePrefix="select"
          styles={getSelectStyles()}
          id={name}
          isDisabled={disabled}
          options={options}
          placeholder={i18n.t('selectPlaceholder')}
          onChange={handleChange}
          defaultValue={value || undefined}
          isSearchable
          isClearable={isClearable}
          value={options ? options.find(o => o[valueKey] === fieldValue) : ''}
          getOptionLabel={o => o[labelKey]}
          getOptionValue={o => o[valueKey]}
          {...inputProps}
        />
      </div>
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
    })
  ).isRequired,
  isClearable: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.func), PropTypes.func]),
};

SelectField.defaultProps = {
  label: undefined,
  value: '',
  className: '',
  disabled: false,
  onChange: () => undefined,
  labelKey: 'label',
  valueKey: 'value',
  isClearable: false,
  validate: undefined,
};

export default React.memo(SelectField);
