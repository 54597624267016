import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ListRow, ListCol } from '../common/list';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { SelectField } from '../common/fields';
import Item from '../../types/Item';
import { moneyFormatter, isNotZohoItem } from '../../helpers/fieldHelpers';

const zeroDollar = '0.00 $';

const InvoiceSummaryRow = ({ errors, item, zohoInvoiceItems, onItemChange }) => {
  const zohoItemFound = useMemo(() => {
    return zohoInvoiceItems.find(
      zohoItem => zohoItem.itemId === item.item_id || (!item.item_id && zohoItem.itemName.toUpperCase() === item.description.toUpperCase())
    );
  }, [item, zohoInvoiceItems]);
  const defaultValue = zohoItemFound || { itemName: item.description, itemId: undefined };

  const clientErrors = errors.filter(e => e.client && e.client.key === item.clientKey && e.error !== 'isNotZohoItem');

  useEffect(() => {
    if (zohoItemFound && !item.item_id) onItemChange(zohoItemFound.itemId, item.clientKey);
  }, [zohoInvoiceItems]);

  return (
    <ListRow>
      <ListCol className="-x-8">
        <WarningIcon className={`icon -list -medium ${clientErrors.length ? '-visible' : '-invisible'}`} />
        <SelectField
          name="zohoItems"
          options={zohoInvoiceItems}
          onChange={itemId => onItemChange(itemId, item.clientKey)}
          value={defaultValue}
          className="input__select -in-form"
          validate={isNotZohoItem}
          labelKey="itemName"
          valueKey="itemId"
          isClearable
        />
      </ListCol>
      <ListCol className="-x-4 -align-right -on-top">{(item.quantity || 0).toFixed(2)}</ListCol>
      <ListCol className="-x-4 -align-right -on-top">{item.rate || 0}</ListCol>
      <ListCol className="-x-4 -align-right -on-top">{moneyFormatter(item.quantity * item.rate) || zeroDollar}</ListCol>
    </ListRow>
  );
};

InvoiceSummaryRow.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      client: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.string,
    })
  ),
  item: PropTypes.shape({ description: PropTypes.string, quantity: PropTypes.number, rate: PropTypes.number }),
  zohoInvoiceItems: PropTypes.arrayOf(Item),
  onItemChange: PropTypes.func.isRequired,
};

InvoiceSummaryRow.defaultProps = {
  errors: [],
  item: {},
  zohoInvoiceItems: [],
};

export default InvoiceSummaryRow;
