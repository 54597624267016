import React from 'react';
import PropTypes from 'prop-types';

import { Children } from '../../../types';

const ListCol = ({ children, className }) => <div className={`list__col ${className}`}>{children}</div>;

ListCol.propTypes = {
  children: Children,
  className: PropTypes.string,
};

ListCol.defaultProps = {
  children: undefined,
  className: '',
};

export default React.memo(ListCol);
