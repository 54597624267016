import React, { useReducer, useEffect } from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import { User, Router } from './types';
import { zohoAgent } from './agents';
import AppContext from './contextes/AppContext';
import Navbar from './containers/Navbar';
import NotFoundPage from './components/errors/NotFoundPage';
import UploadPage from './containers/UploadPage';
import ReportListPage from './containers/ReportListPage';
import CarrierInvoicePage from './containers/CarrierInvoicePage';
import InvoiceCreationSuccessPage from './containers/InvoiceCreationSuccessPage';
import { useAgent } from './hooks';
import { Loader } from './components/common';

export const defaultState = { user: {}, navbarTitle: '' };

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_NAVBAR_TITLE': {
      return { ...state, navbarTitle: action.payload };
    }
    default:
      return state;
  }
};

const App = ({ user, history }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [generateTokens] = useAgent(zohoAgent.generateTokens);
  const params = new URLSearchParams(window.location.search);
  const isGeneratingToken = !!params.get('code');

  useEffect(() => {
    dispatch({ type: 'SET_USER', payload: user });

    const urlParams = new URLSearchParams(window.location.search);
    const zohoCode = urlParams.get('code');

    if (zohoCode)
      generateTokens(zohoCode).then(() => {
        const urlRedirect = localStorage.getItem('redirectUrl');

        if (urlRedirect) {
          localStorage.removeItem('redirectUrl');
          history.replace(urlRedirect);
        } else {
          history.replace('/');
        }
      });
  }, []);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <div className="app">
        <ToastContainer transition={Slide} hideProgressBar />
        <Navbar />
        {isGeneratingToken && <Loader />}

        {!isGeneratingToken && (
          <main className="app__viewport">
            <div className="layout">
              <Switch>
                <Route exact path="/" component={UploadPage} />
                <Route exact path="/reports" component={ReportListPage} />
                <Route exact path="/invoices/:id" component={CarrierInvoicePage} />
                <Route exact path="/invoices/success/:id" component={InvoiceCreationSuccessPage} />
                <Route exact path="/404" component={NotFoundPage} />
                <Redirect to="/404" />
              </Switch>
            </div>
          </main>
        )}
      </div>
    </AppContext.Provider>
  );
};

App.propTypes = {
  user: User.isRequired,
  history: Router.History.isRequired,
};

const AppWithRouter = props => (
  <BrowserRouter>
    <Route render={routerProps => <App {...routerProps} {...props} />} />
  </BrowserRouter>
);

export default AppWithRouter;
